/* eslint-disable */
import { Box, Grid, Link, List, ListItem, RACBadge, RACCOLOR, Typography } from "@rentacenter/racstrap";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PathParams } from "../../../interface/globalInterface";
import { updateCustomerStyles } from "../../../jsStyles/updateCustomerStyles";
import { FeatureFlagContext } from "../../../../context/FeatureFlagContext";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import moment from "moment";
import { getpaymentHistory, getResidenceTenureTypeOptions, getVerificationNotRequiredReason } from "../../../../api/user";
import { CUSTOMER_APPROVAL_FLOW, DECISION_ENGINE_APPROVED, DECISION_ENGINE_PREAPPROVED, DECISON_ENGINE_DECLINED, DECISON_ENGINE_EXPIRED, NO_DECISION_ENGINE } from "../../../constants/constants";

export default function UpdateCustomerHeader(): JSX.Element {

  const history = useHistory();
  const { customerId } = useParams<PathParams>();
  const { featureFlagDetails } = useContext(FeatureFlagContext)
  const { getCustomerInfoResponse, setVerificationNotRequiredReason, setResidenceTenureTypeOptions } = useContext(UpdateCustomerContext)
  const [showLevels, setShowLevels] = useState(false)
  let [getCustomerRes, setGetCustomerRes] = useState<any>();
  let [getApprovalRes, setGetApprovalRes] = useState<any>();
  const [totalRentalRevenueAmount, settotalRentalRevenueAmount] = useState<any>()
  const [getpaymentHistoryCalled, setgetpaymentHistoryCalled] = useState(false)



  // const ApprovedObj = 'DE Approved';
  // const ConditionalApproved = 'Conditional Approval';

  useEffect(() => {
    if (getCustomerInfoResponse) {
      console.log("getCustomerInfoResponsegetCustomerInfoResponse", getCustomerInfoResponse)

      getCustomerRes = getCustomerInfoResponse?.GetCustomer?.value;
      setGetCustomerRes(getCustomerRes)

      getApprovalRes = getCustomerInfoResponse?.GetApproval?.value?.customerApprovalDetails
      setGetApprovalRes(getApprovalRes)
    }
    getpaymentHistoryRes()


  }, [getCustomerInfoResponse])

  const getpaymentHistoryRes = async () => {
    setgetpaymentHistoryCalled(true)


    if (!getpaymentHistoryCalled) {
      let getpaymentHistoryResponse: any
      let verificationNotRequiredReasonRes: any
      let residenceTenureTypeOptionsRes: any


      await Promise.all([
        getpaymentHistory(customerId,
          "agr", 0),
        getVerificationNotRequiredReason("customer_approval_override_type"),
        getResidenceTenureTypeOptions('residence_tenure_type')
      ]).then((resp) => {
        [getpaymentHistoryResponse, verificationNotRequiredReasonRes, residenceTenureTypeOptionsRes] = resp
      })
      if (getpaymentHistoryResponse?.status == 200) {
        settotalRentalRevenueAmount(getpaymentHistoryResponse?.data?.value?.customerInfo?.totalRentalRevenue)
      }
      else {
        settotalRentalRevenueAmount(0)
      }
      if (verificationNotRequiredReasonRes?.status == 200) {

        const verificationNotReqReasons = verificationNotRequiredReasonRes?.data?.referenceDetails?.map((obj: any) => {
          return { label: obj.description, value: obj.description }
        })
        verificationNotReqReasons.splice(0, 0, {
          value: '0',
          label: 'Select',
        });
        verificationNotReqReasons.push({
          value: 'Other',
          label: 'Other',
        })
        setVerificationNotRequiredReason(verificationNotReqReasons)
      }
      if (residenceTenureTypeOptionsRes?.status == 200) {

        const residenceTenureTypeOptions = residenceTenureTypeOptionsRes?.data?.referenceDetails?.map((obj: any) => {
          return { label: obj.description, value: obj.referenceCode }
        })
        residenceTenureTypeOptions.splice(0, 0, {
          value: '',
          label: 'Select',
        })
        setResidenceTenureTypeOptions(residenceTenureTypeOptions)
      }
    }

  }



  const approvalStatus: any =
    getApprovalRes !== null &&
      getApprovalRes?.approvalStatus !== undefined &&
      getApprovalRes?.approvalStatus !== null &&
      getApprovalRes?.approvalStatus !== ""
      ? getApprovalRes?.approvalStatus == "E"
        ? DECISON_ENGINE_EXPIRED
        : getApprovalRes?.approvalStatus == "M"
          ? DECISION_ENGINE_PREAPPROVED
          : getApprovalRes?.approvalStatus == "D"
            ? DECISON_ENGINE_DECLINED
            : getApprovalRes?.approvalStatus == "A"
              ? DECISION_ENGINE_APPROVED
              : NO_DECISION_ENGINE
      : NO_DECISION_ENGINE;

  const classes = updateCustomerStyles();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [verExpDate, setverExpDate] = useState<any>(60);


  const buildVerification = () => {
    let myFutureDate: any = '';
    if (
      getCustomerRes?.verifiedDate !== undefined &&
      getCustomerRes?.verifiedDate !== null &&
      getCustomerRes?.verifiedDate !== ''
    ) {
      console.log('testing date', getCustomerRes?.verifiedDate);

      myFutureDate = new Date(
        getCustomerRes?.verifiedDate.replace('Z', '')
      );
      console.log('testing date', myFutureDate);
      myFutureDate.setDate(myFutureDate.getDate() + parseInt(verExpDate));
      console.log('testing date', myFutureDate);
    }
    return getCustomerRes?.verified !== undefined &&
      getCustomerRes?.verified !== null &&
      getCustomerRes?.verified !== '' &&
      getCustomerRes?.verified == 'Y' &&
      getCustomerRes?.verifiedDate !== undefined &&
      getCustomerRes?.verifiedDate !== null &&
      getCustomerRes?.verifiedDate !== '' ? (
      <Grid
        item
        className={`${classes.floatLeft} ${classes.textCenter} ${classes.me3}`}
      >
        <Box
          component="span"
          className={`${classes.badge} ${classes.badgeGreen}`}
        >
          Verified
        </Box>
        <Box component="span" className={classes.racDEDate}>
          Expires on{' '}
          {getCustomerRes?.verifiedDate !== undefined &&
            getCustomerRes?.verifiedDate !== null &&
            getCustomerRes?.verifiedDate !== ''
            ? buildDateFormat2(myFutureDate, 'V')
            : ''}
        </Box>
      </Grid>
    ) : getCustomerRes?.verified == 'N' ||
      getCustomerRes?.verifiedDate == undefined ||
      getCustomerRes?.verifiedDate == null ||
      getCustomerRes?.verifiedDate == '' ? (
      <Grid
        item
        className={`${classes.floatLeft} ${classes.textCenter} ${classes.mt1} ${classes.me1}`}
      >
        <Box
          component="span"
          className={`${classes.badge} ${classes.badgeRed}`}
        >
          Verification Required
        </Box>
      </Grid>
    ) : null;
  };
  const buildDateFormat2 = (date: any, type?: 'V') => {

    console.log(date, 'date Vanthiduchuuuuu 123');
    const d = date == '' ? new Date() : date;
    return moment(d).format('MM/DD/YYYY');
  };
  return (
    <Fragment>
      {/* New Header Start */}
      <Grid className={classes.parentclass}>
        <Grid item md={12} className={classes.flexratio}>
          <List className={`${classes.breadcrumb} ${classes.py1}`}>
            <ListItem
              className={`${classes.breadcrumbItemActive} ${classes.breadCrumbMarg}`}
            >
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/dashboard`)}
              >
                Dashboard
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem} ${classes.breadcrumbArrow} ${classes.racpadLinkCustomer}`}
            >

              {featureFlagDetails?.CustomerRewards == '1' && getCustomerRes?.firstName && totalRentalRevenueAmount && Number(totalRentalRevenueAmount) >= Number(featureFlagDetails.RewardsEligibilityAmount) ?
                <>
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <span
                      onMouseOver={() => {
                        setShowTooltip(true);
                      }}
                      onMouseOut={() => {
                        setShowTooltip(false);
                      }}
                      style={{
                        backgroundColor: '#198754',
                        color: 'white',
                        padding: '1px 7px',
                        borderRadius: '11px',
                        fontSize: '12px',
                        cursor: 'pointer'
                      }}
                      onClick={() => { history.push(`/customer/update/${customerId}/customer`) }}
                    >
                      {getCustomerRes?.firstName +
                        ' ' +
                        getCustomerRes?.lastName}
                    </span>
                    {showTooltip && (
                      <div style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        marginBottom: '5px',
                        backgroundColor: '#e9f1f6',
                        color: 'black',
                        padding: '5px',
                        borderRadius: '10px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        zIndex: 99999
                      }}>
                        Eligible for RAC Select Rewards
                      </div>
                    )}
                  </div>
                </>
                : getCustomerRes?.firstName
                  ? getCustomerRes?.firstName +
                  ' ' +
                  getCustomerRes?.lastName
                  : ''
              }
              {' '}
              - {customerId}
            </ListItem>
          </List>
          <Grid className={classes.overall}>
            {approvalStatus !== DECISON_ENGINE_EXPIRED ?
              <Typography className={classes.agrInfoPanelviewagr}>
                <Typography variant="caption" className={classes.approvalAmt}>
                  Approval Amount :
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.racpadLink} ${classes.check}`}
                >
                  $
                  {' ' + getApprovalRes &&
                    getApprovalRes?.approvalAmount &&
                    getApprovalRes?.approvalAmount != null
                    ? parseFloat(getApprovalRes?.approvalAmount)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '0.00'}
                </Typography>
              </Typography> : null}
            {/* </Grid> */}
            {
              approvalStatus !== DECISON_ENGINE_EXPIRED ? <Typography className={classes.agrInfoPanelviewagr}>
                <Typography variant="caption" className={classes.approvalAmt}>
                  Weekly Approval Amount :
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.racpadLink} ${classes.check}`}
                >
                  $
                  {' ' + getApprovalRes &&
                    getApprovalRes?.weeklyApprovalAmount &&
                    getApprovalRes?.weeklyApprovalAmount != null
                    ? parseFloat(getApprovalRes?.weeklyApprovalAmount)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '0.00'}
                </Typography>
              </Typography> : null}

            {/* </Grid> */}
            {/* Customer type binding starting*/}


            {featureFlagDetails?.CustomerVerificationOptimization == 1 ? <>

              {getCustomerRes && getApprovalRes && (getApprovalRes?.verifyCode == '1' || getApprovalRes?.verifyCode == '-1' || getApprovalRes?.verifyCode == '0' || getApprovalRes?.verifyCode == null) ? <>
                <span
                  style={{
                    backgroundColor: '#bb37ae',
                    color: 'white',
                    borderRadius: '11px',
                    marginRight: getApprovalRes?.verificationStatus != 'COMPLETED' && getApprovalRes?.verificationStatus != 'PENDING' && getApprovalRes?.approvalStatus != 'E' && getApprovalRes?.approvalStatus != 'D' && getApprovalRes?.verificationStatus != 'REVIEW PENDING' ? '-28px' : getApprovalRes?.approvalStatus != 'E' && getApprovalRes?.approvalStatus != 'D' && getApprovalRes?.verificationStatus != 'REVIEW PENDING' ? '13px' : '7px',
                    marginTop: getApprovalRes?.verificationStatus != 'COMPLETED' && getApprovalRes?.verificationStatus != 'PENDING' && getApprovalRes?.approvalStatus != 'E' && getApprovalRes?.approvalStatus != 'D' ? '7px' : getApprovalRes?.approvalStatus == 'M' || getApprovalRes?.approvalStatus == 'A' ? '11px' : getApprovalRes?.approvalStatus != 'E' ? '9PX' : '10px',
                    float: 'left',
                    padding: '5px 10px',
                    fontSize: '0.75em',
                    fontWeight: 700,
                    lineHeight: 1,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'baseline',

                  }}
                >
                  {getCustomerRes?.salesLeadId && (getApprovalRes.verifyCode == '-1' || getApprovalRes.verifyCode == null) ? 'Previous-Web' : getCustomerRes?.salesLeadId && (getApprovalRes.verifyCode == '0' || getApprovalRes.verifyCode == '1') ? 'New-Web' : (getApprovalRes.verifyCode == '-1' || getApprovalRes.verifyCode == null) ? 'Previous' : 'New'}
                </span>
              </> : null}
              {/* Customer type binding end */}

              {/* Verification Level badge start*/}
              {getApprovalRes?.verifyLevel && (getApprovalRes?.verificationStatus == 'COMPLETED') ? <span
                style={{
                  backgroundColor: '#0d6efd',
                  color: 'white',
                  borderRadius: '11px',
                  marginRight: getApprovalRes?.approvalStatus == 'A' || getApprovalRes?.approvalStatus == 'M' ? '-22px' : '13px',
                  marginTop: getApprovalRes?.approvalStatus == 'A' || getApprovalRes?.approvalStatus == 'M' ? '11px' : '9px',
                  float: 'left',
                  padding: '5px 10px',
                  fontSize: '0.75em',
                  fontWeight: 700,
                  lineHeight: 1,
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  verticalAlign: 'baseline',

                }}
              >
                {'Verified'}
              </span> : getApprovalRes?.verificationStatus == 'PENDING' ?
                <span style={{ position: 'relative' }}>
                  <span
                    onMouseOver={() => {
                      setShowLevels(true);
                    }}
                    onMouseOut={() => {
                      setShowLevels(false);
                    }}
                    style={{
                      backgroundColor: '#e85c5c',
                      color: 'white',
                      borderRadius: '11px',
                      marginRight: getApprovalRes?.approvalStatus == 'A' || getApprovalRes?.approvalStatus == 'M' ? '-23px' : '13px',
                      float: 'left',
                      padding: '5px 10px',
                      fontSize: '0.75em',
                      fontWeight: 700,
                      lineHeight: 1,
                      textAlign: 'center',
                      whiteSpace: 'nowrap',
                      verticalAlign: 'baseline',
                      cursor: 'pointer',
                      marginTop: getApprovalRes?.approvalStatus == 'A' || getApprovalRes?.approvalStatus == 'M' ? '9px' : '10px'
                    }}
                  >
                    {getApprovalRes && getApprovalRes?.verificationStatus != 'COMPLETED' ? `Level - ${getApprovalRes?.verifyLevel.replace('-1', '3')} ` : 'Verified'}
                  </span>
                  {showLevels && (
                    <div style={{
                      position: 'absolute',
                      bottom: '0px',
                      right: getApprovalRes?.verifyLevel == 1 ? '-53px' : getApprovalRes?.verifyLevel == 2 ? '-110px' : '-170px',
                      transform: 'translateX(-50%)',
                      marginBottom: '5px',
                      backgroundColor: '#e9f1f6',
                      color: 'black',
                      padding: '5px',
                      borderRadius: '10px',
                      fontSize: '12px',
                      whiteSpace: 'nowrap',
                      zIndex: 2000,
                      fontWeight: 'bold'
                    }}>
                      {getApprovalRes?.verifyLevel == 1 ? 'Identity and Residence Verification Required' : getApprovalRes?.verifyLevel == 2 ? 'Identity, Residence, and Employment Verification Required' : getApprovalRes?.verifyLevel == 3 || getApprovalRes?.verifyLevel == -1 ? 'Identity, Residence, Employment, and Reference Verification Required' : ''}
                    </div>
                  )}
                </span> : getApprovalRes?.verifyLevel && (getApprovalRes?.verificationStatus == 'REVIEW PENDING') ? <span
                  style={{
                    backgroundColor: 'rgb(253 180 13)',
                    color: 'white',
                    borderRadius: '11px',
                    marginTop: '7px',
                    float: 'left',
                    padding: '5px 10px',
                    fontSize: '0.75em',
                    fontWeight: 700,
                    lineHeight: 1,
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'baseline',

                  }}
                >
                  {'Review Pending'}
                </span> : null}

              {/* Verification Level badge end */}
            </> : null}

            {/* <React.Fragment>
              {approvalStatus !== 'DE Approved' &&
                buildVerification()}
            </React.Fragment> */}

            {getApprovalRes !== undefined &&
              approvalStatus == DECISION_ENGINE_APPROVED ? (
              <Grid
                item
                className={`${classes.floatLeft} ${classes.textCenter} ${classes.me2}`}
              >
                <Box
                  component="span"
                  className={`${classes.badge} ${classes.badgeGreen}`}
                >
                  {approvalStatus}
                </Box>
                <Box component="span" className={classes.racDEDate}>
                  Expires on{' '}
                  {buildDateFormat2(getApprovalRes?.approvalExpirationDate)}
                </Box>
              </Grid>
            ) : approvalStatus == DECISON_ENGINE_DECLINED ? (
              <Grid
                item
                className={`${classes.floatRight} ${classes.textCenter} ${classes.mt1}`}
              >
                <Box
                  component="span"
                  className={`${classes.badge} ${classes.badgeRed}`}
                >
                  {approvalStatus}
                </Box>
              </Grid>
            ) : approvalStatus == DECISION_ENGINE_PREAPPROVED ? (
              <Grid
                item
                className={`${classes.floatRight} ${classes.textCenter} ${classes.mt1}`}
              >
                <Box
                  component="span"
                  className={approvalStatus == DECISION_ENGINE_PREAPPROVED && CUSTOMER_APPROVAL_FLOW ? `${classes.badge} ${classes.badgeGreen}` : `${classes.badge} ${classes.badgeOrange}`}
                >
                  {approvalStatus == DECISION_ENGINE_PREAPPROVED && CUSTOMER_APPROVAL_FLOW ? DECISION_ENGINE_APPROVED : DECISION_ENGINE_PREAPPROVED}
                </Box>
                <Box component="span" className={classes.racDEDate}>
                  Expires on{' '}
                  {buildDateFormat2(getApprovalRes?.approvalExpirationDate)}
                </Box>
              </Grid>
            ) : approvalStatus !== undefined &&
              approvalStatus !== null &&
              approvalStatus !== '' ? (
              <Grid
                item
                className={`${classes.floatRight} ${classes.textCenter} ${classes.mt1}`}
              >
                <Box
                  component="span"
                  className={`${classes.badge} ${classes.badgeRed}`}
                >
                  {approvalStatus}
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      {/* New Header End */}

    </Fragment >
  )
}