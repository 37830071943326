/* eslint-disable */
import moment from "moment";

import {
  ADDRESS_INFO_TEMPLATE,
  BASIC_INFORMATION_TEMPLATE,
  COMMUNICATION_PREFERENCE_TEMPLATE,
  DEFAULT_DROPDOWN_OPTION,
  EMPLOYER_INFO_TEMPLATE,
  EMPTY_STRING,
  LOGGED_IN_STORE,
  IDENTITY_DETAILS,
  REFERENCE_INFO_TEMPLATE,
  RESIDENCE_INFO_TEMPLATE,
  SKIP_HARD_TEMPLATE,
  STRING_NO,
  STRING_YES,
  STRING_ZERO,
  STRING_ZERO_AMOUNT,
  TAX_EXEMPT_TEMPLATE,
  VEHICLE_INFORMATION_TEMPLATE,
  VEHICLE_YEAR_DROPDOWN,
  VERIFICATION_EXPIRY_DAYS,
} from "../../constants/constants";

import {
  CommunicationInformation,
  ContactInformations,
  CustomerBasicInformation,
  GetCustomerResponse,
  ReferenceInformation,
  ResidenceInformation,
  SkipHardStatus,
  TaxExemptInformation,
} from "../../interface/updateCustomerInterface";
import {
  AddressDetails,
  AddressInfo,
  DropdownOptions,
  EmployerDetails,
  IdentityDetailsInfo,
  ResidenceInfo,
  VehicleInfomation,
} from "../../interface/globalInterface";
import {
  formatAmount,
  formatDate,
  formDropdownOptions,
  validateVerificationDate,
  valueExistsInDropdown,
} from "../../utils/utils";
import crypto, { createDecipheriv } from "crypto";
import { decryptData } from "../../api/user";
const buildBasicInformation = async (
  basicInformationResponse: GetCustomerResponse,
  featureFlagDetails: boolean
): Promise<CustomerBasicInformation> => {
  try {
    const encryptKey = crypto.randomBytes(16).toString("hex");
    const ivKey = crypto.randomBytes(8).toString("hex");

    const basicInformation: CustomerBasicInformation = {
      ...BASIC_INFORMATION_TEMPLATE,
    };
    basicInformation.firstName =
      basicInformationResponse.firstName || EMPTY_STRING;
    basicInformation.lastName =
      basicInformationResponse.lastName || EMPTY_STRING;
    basicInformation.dateOfBirth =
      moment(basicInformationResponse.dateOfBirth).format("MM/DD/YYYY") ||
      EMPTY_STRING;
    basicInformation.ssn = basicInformationResponse.taxId
      ? basicInformationResponse.taxId
      : EMPTY_STRING;
    basicInformation.emailAddress =
      basicInformationResponse.emailAddress || EMPTY_STRING;
    basicInformation.governmentIdType =
      basicInformationResponse.governmentIdType || EMPTY_STRING;
    basicInformation.governmentId =
      basicInformationResponse.governmentId || EMPTY_STRING;
    basicInformation.governmentIdIssuedState =
      basicInformationResponse.governmentIdIssuedState || EMPTY_STRING;
    basicInformation.salutation =
      basicInformationResponse.salutation || EMPTY_STRING;
    basicInformation.middleInitial =
      basicInformationResponse.middleInitial || EMPTY_STRING;
    basicInformation.suffix = basicInformationResponse.suffix || EMPTY_STRING;
    basicInformation.racCoworker =
      basicInformationResponse.coworker || EMPTY_STRING;
    basicInformation.globalCustomerId =
      basicInformationResponse.globalCustomerId || EMPTY_STRING;
    basicInformation.dateOfBirthEnc =
      basicInformationResponse.dateOfBirthEnc || EMPTY_STRING;
    basicInformation.taxIdEnc =
      basicInformationResponse.taxIdEnc || EMPTY_STRING;
    basicInformation.governmentIdEnc =
      basicInformationResponse.governmentIdEnc || EMPTY_STRING;
    basicInformation.verified =
      basicInformationResponse.verified &&
        basicInformationResponse.verified == STRING_YES
        ? STRING_YES
        : STRING_NO;
    basicInformation.verifiedDate =
      basicInformationResponse.verifiedDate || EMPTY_STRING;
    basicInformation.oktaId = basicInformationResponse.oktaId || EMPTY_STRING;
    basicInformation.isEditEnabled = featureFlagDetails;
    const addresses = basicInformationResponse.addresses;
    if (addresses && addresses.length) {
      const primaryAddresses = addresses.find(
        (element: any) => element.addressType == "PRIM"
      );
      basicInformation.addressLine1 = primaryAddresses?.addressLine1
        ? primaryAddresses?.addressLine1
        : EMPTY_STRING;
      basicInformation.addressLine2 = primaryAddresses?.addressLine2
        ? primaryAddresses?.addressLine2
        : EMPTY_STRING;
      basicInformation.zip = primaryAddresses?.postalCode
        ? primaryAddresses?.postalCode
        : EMPTY_STRING;
      basicInformation.city = primaryAddresses?.city
        ? primaryAddresses?.city
        : EMPTY_STRING;
      basicInformation.state = primaryAddresses?.state
        ? primaryAddresses?.state
        : EMPTY_STRING;
    }
    const decrytptedValues: any = await decryptData({
      decryptData: {
        dateOfBirth: basicInformationResponse.dateOfBirthEnc
          ? basicInformationResponse.dateOfBirthEnc
          : undefined,
        taxId: basicInformationResponse.taxIdEnc
          ? basicInformationResponse.taxIdEnc
          : undefined,
        governmentId: basicInformationResponse.governmentIdEnc
          ? basicInformationResponse.governmentIdEnc
          : undefined,
      },
      encryptKey,
      ivKey,
    });
    if (decrytptedValues.status == 200 && decrytptedValues?.data?.decryptData) {
      basicInformation.dateOfBirth = decrytptedValues?.data?.decryptData
        ?.dateOfBirth
        ? getData(
          encryptKey,
          ivKey,
          decrytptedValues?.data?.decryptData?.dateOfBirth
        )
        : EMPTY_STRING;
      basicInformation.dateOfBirthDec = decrytptedValues?.data?.decryptData
        ?.dateOfBirth
        ? getData(
          encryptKey,
          ivKey,
          decrytptedValues?.data?.decryptData?.dateOfBirth
        )
        : EMPTY_STRING;
      basicInformation.taxIdDecr = decrytptedValues?.data?.decryptData?.taxId
        ? getData(encryptKey, ivKey, decrytptedValues?.data?.decryptData?.taxId)
        : EMPTY_STRING;
      basicInformation.ssn = decrytptedValues?.data?.decryptData?.taxId
        ? getData(encryptKey, ivKey, decrytptedValues?.data?.decryptData?.taxId)
        : EMPTY_STRING;
      basicInformation.govtIdDecr = decrytptedValues?.data?.decryptData
        ?.governmentId
        ? getData(
          encryptKey,
          ivKey,
          decrytptedValues?.data?.decryptData?.governmentId
        )
        : EMPTY_STRING;
    }
    return basicInformation;
  } catch (error: any) {
    console.log("Error in buildBasicInformation: ", error?.message);
    return { ...BASIC_INFORMATION_TEMPLATE };
  }
};

const buildCommunicationPreference = (
  communicationResponse: GetCustomerResponse,
  isEditEnabled: boolean
): CommunicationInformation => {
  try {
    const communicationInformation: CommunicationInformation = {
      ...COMMUNICATION_PREFERENCE_TEMPLATE,
    };

    communicationInformation.paymentRemainderCall =
      communicationResponse.paymentReminderCallsAllowed || EMPTY_STRING;
    communicationInformation.contactMethod =
      communicationResponse.preferredContactMethod || EMPTY_STRING;
    communicationInformation.textMessageRemainder =
      communicationResponse.textSolicitationAllowed || EMPTY_STRING;
    communicationInformation.paymentReceipt =
      communicationResponse.printReceiptMethod || EMPTY_STRING;
    communicationInformation.language =
      communicationResponse.preferredLanguage || EMPTY_STRING;
    communicationInformation.phoneSolicitation =
      communicationResponse.phoneSolicitationAllowed || EMPTY_STRING;
    communicationInformation.mailSolicitation =
      communicationResponse.mailSolicitationAllowed || EMPTY_STRING;
    communicationInformation.emailSolicitation =
      communicationResponse.emailSolicitationAllowed || EMPTY_STRING;
    communicationInformation.isEditEnabled = isEditEnabled;

    if (communicationResponse.customerRoutes?.length) {
      communicationInformation.route =
        communicationResponse.customerRoutes.find(
          (routeInfo: any) => routeInfo.storeNumber == LOGGED_IN_STORE
        )?.routeCode || "";
    }

    return communicationInformation;
  } catch (error: any) {
    console.log("Error in buildCommunicationPreference: ", error?.message);
    return {
      ...COMMUNICATION_PREFERENCE_TEMPLATE,
    };
  }
};

const buildSkipStolenHardInfo = (
  skipStolenHardResponse: GetCustomerResponse
): SkipHardStatus => {
  try {
    const skipStolenHardStatus: SkipHardStatus = { ...SKIP_HARD_TEMPLATE };
    skipStolenHardStatus.skip =
      skipStolenHardResponse.skip && skipStolenHardResponse.skip === STRING_YES
        ? true
        : false;
    skipStolenHardStatus.hard =
      skipStolenHardResponse.hard && skipStolenHardResponse.hard === STRING_YES
        ? true
        : false;
    skipStolenHardStatus.stolen =
      skipStolenHardResponse.stolen &&
        skipStolenHardResponse.stolen === STRING_YES
        ? true
        : false;

    return skipStolenHardStatus;
  } catch (error: any) {
    console.log("Error in buildSkipStolenHardInfo: ", error?.message);
    return { ...SKIP_HARD_TEMPLATE };
  }
};

const buildResidenceInformation = (
  residenceResponse: GetCustomerResponse,
  getApprovalResponse: any
): ResidenceInfo => {
  const residenceInformation: ResidenceInfo = {
    ...RESIDENCE_INFO_TEMPLATE,
  };
  try {
    const landlordReferences = residenceResponse.landlordReferences?.[0] || {};
    const getApprovalRes =
      getApprovalResponse.value.customerApprovalDetails || {};
    residenceInformation.rentalType = residenceResponse ? residenceResponse?.residenceType : EMPTY_STRING;
    residenceInformation.mortgage = residenceResponse?.mortgageCompanyName && residenceResponse?.residenceType != 'RENT' ? true : false;
    residenceInformation.ownResidenceDetails = {
      paymentAmount: residenceResponse.monthlyMortgagePayment || EMPTY_STRING,
      mortgageCompanyName:
        residenceResponse.mortgageCompanyName || EMPTY_STRING,
      moveInDate: residenceResponse.residenceSince || EMPTY_STRING,
    };
    residenceInformation.rentalDetails = {
      landlordsFirstName: landlordReferences.landlordFirstName || EMPTY_STRING,
      landlordsLastName: landlordReferences.landlordLastName || EMPTY_STRING,
      moveInDate: landlordReferences.moveInDate || EMPTY_STRING,
      landlordsPhoneNumber: landlordReferences.phoneNumber || EMPTY_STRING,
      paymentAmount: landlordReferences.monthlyRent || EMPTY_STRING,
      landlordOrAptFirstName: landlordReferences?.landlordFirstName || EMPTY_STRING,
      residenceTenureType: landlordReferences?.residenceTenureType || EMPTY_STRING,
      landlordOrAptPhoneNumber: landlordReferences.phoneNumber || EMPTY_STRING
    };
    residenceInformation.landlordReferenceId = landlordReferences?.landlordReferenceId || EMPTY_STRING;

    residenceInformation.verified = getApprovalRes?.verificationDocuments?.length &&
      getApprovalRes?.verificationDocuments?.find(
        (element: any) => element.verifyType == "RESIDENCE"
      )?.verificationStatus == "VERIFIED"
    residenceInformation.verifiedDate = getApprovalRes?.verificationDocuments?.length &&
      getApprovalRes?.verificationDocuments?.find(
        (element: any) => element.verifyType == "RESIDENCE"
      )?.lastModifiedDate?.split(
        "T"
      )[0] || EMPTY_STRING;
    residenceInformation.decisionId =
      getApprovalRes?.decisionId || EMPTY_STRING;
    residenceInformation.verifyCode =
      getApprovalRes?.verifyCode || EMPTY_STRING;
    residenceInformation.verifiedOn = getApprovalRes?.verificationDocuments?.length &&
      getApprovalRes?.verificationDocuments?.find(
        (element: any) => element.verifyType == "RESIDENCE"
      )?.lastModifiedDate ||
      EMPTY_STRING;
    residenceInformation.verifiedBy = getApprovalRes?.verificationDocuments?.length &&
      getApprovalRes?.verificationDocuments?.find(
        (element: any) => element.verifyType == "RESIDENCE"
      )?.lastModifiedBy ||
      EMPTY_STRING;

    residenceInformation.verifiedSource = getApprovalRes?.verificationDocuments?.length &&
      getApprovalRes?.verificationDocuments?.find(
        (element: any) => element.verifyType == "RESIDENCE"
      )?.clientId ||
      EMPTY_STRING;
    residenceInformation.residenceVerificationToggle = getApprovalRes?.verificationDocuments?.length &&
      getApprovalRes?.verificationDocuments?.find(
        (element: any) => element.verifyType == "RESIDENCE"
      )?.verificationStatus == "VERIFIED"

  } catch (error: any) {
    console.log("Error in buildResidenceInformation: ", error?.message);
  } finally {
    return residenceInformation;
  }
};

const buildInformationPageDropdowns = (
  dropdownsData: any
): Array<DropdownOptions[]> => {
  try {
    let relationshipOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let govtIDTypeOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let legalHoldOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let saluationOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let suffixOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let bestTimeToCallOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let phoneTypeOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let preferredContactOptions: DropdownOptions[] = [
      ...DEFAULT_DROPDOWN_OPTION,
    ];
    let taxExemptStatusOptions: DropdownOptions[] = [
      ...DEFAULT_DROPDOWN_OPTION,
    ];
    let stateOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let routeOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];
    let incomeSourceOptions: DropdownOptions[] = [...DEFAULT_DROPDOWN_OPTION];

    relationshipOptions = formDropdownOptions(
      dropdownsData.relationshipType,
      "description",
      "referenceCode"
    );

    govtIDTypeOptions = formDropdownOptions(
      dropdownsData.governmentIdTypeOptions,
      "description",
      "referenceCode"
    );

    legalHoldOptions = formDropdownOptions(
      dropdownsData.resLegalHoldList,
      "description",
      "referenceCode"
    );
    saluationOptions = formDropdownOptions(
      dropdownsData.salutationOptions,
      "description",
      "referenceCode"
    );

    suffixOptions = formDropdownOptions(
      dropdownsData.nameSuffix,
      "referenceCode",
      "referenceCode"
    );

    bestTimeToCallOptions = formDropdownOptions(
      dropdownsData.callTimeList,
      "description",
      "referenceCode",
      true
    );
    phoneTypeOptions = formDropdownOptions(
      dropdownsData.phoneTypeList,
      "description",
      "referenceCode",
      true
    );
    preferredContactOptions = formDropdownOptions(
      dropdownsData.contactMethodList,
      "description",
      "referenceCode"
    );
    taxExemptStatusOptions = formDropdownOptions(
      dropdownsData.taxExemptStatusList,
      "description",
      "referenceCode"
    );

    stateOptions = formDropdownOptions(
      dropdownsData.stateList?.getStateResponse,
      "abbreviation",
      "abbreviation"
    );
    routeOptions = formDropdownOptions(
      dropdownsData.getStoreRoutes?.routeIds,
      "description",
      "routeRefCode"
    );

    incomeSourceOptions = formDropdownOptions(
      dropdownsData?.incomeSourceTypes,
      "description",
      "referenceCode"
    )
    return [
      relationshipOptions,
      govtIDTypeOptions,
      legalHoldOptions,
      saluationOptions,
      suffixOptions,
      bestTimeToCallOptions,
      phoneTypeOptions,
      preferredContactOptions,
      taxExemptStatusOptions,
      stateOptions,
      routeOptions,
      incomeSourceOptions
    ];
  } catch (error: any) {
    console.log("Error in buildInformationPageDropdowns: ", error?.message);
    const emptyDropdown: Array<DropdownOptions[]> = [];
    for (let i = 0; i <= 11; i++) {
      emptyDropdown.push([...DEFAULT_DROPDOWN_OPTION]);
    }
    return emptyDropdown;
  }
};

const buildVehicleinformation = (
  vehicleInformation: any[]
): VehicleInfomation[] => {
  const vehicleDetails: VehicleInfomation[] = [];
  try {
    vehicleInformation.forEach((information: any) => {
      const informationTemplate: VehicleInfomation = {
        ...VEHICLE_INFORMATION_TEMPLATE,
      };

      informationTemplate.vehicleId =
        information.customerVehicleId || EMPTY_STRING;
      informationTemplate.make = information.vehicleMake || EMPTY_STRING;
      informationTemplate.model = information.vehicleModel || EMPTY_STRING;
      informationTemplate.vehicleVin = information.vehicleVin || EMPTY_STRING;
      informationTemplate.year = information.vehicleYear
        ? valueExistsInDropdown(VEHICLE_YEAR_DROPDOWN, information.vehicleYear)
        : EMPTY_STRING;
      informationTemplate.financedFrom =
        information.vehFinancedFrom || EMPTY_STRING;
      informationTemplate.licensePlate =
        information.vehicleLicensePlate || EMPTY_STRING;
      informationTemplate.monthlyPayment =
        information.vehMonthlyPayment && information.vehMonthlyPayment !== "NaN"
          ? formatAmount(information.vehMonthlyPayment)
          : EMPTY_STRING;
      informationTemplate.planExpirationDate = information.vehPlanExpirationDate
        ? formatDate(information.vehPlanExpirationDate)
        : EMPTY_STRING;
      informationTemplate.regState = information.vehRegState || EMPTY_STRING;
      informationTemplate.vehicleIndex =
        information.vehicleIndex || EMPTY_STRING;
      informationTemplate.color = information.vehicleColor || EMPTY_STRING;
      informationTemplate.vehicleTagNumber = information.vehicleTagNumber;
      vehicleDetails.push(informationTemplate);
    });
  } catch (error: any) {
    console.log("Error in buildVehicleinformation", error?.message);
  } finally {
    if (!vehicleDetails.length) {
      vehicleDetails.push({ ...VEHICLE_INFORMATION_TEMPLATE });
    }

    return vehicleDetails;
  }
};

const buildContactInformation = (
  contactResponse: any[],
  isEditEnabled: boolean
): ContactInformations[] => {
  try {
    const contactInformation: ContactInformations[] = [];
    contactResponse.forEach((contactInfo: any) => {
      const iteratedContact: ContactInformations = {
        phoneId: contactInfo.phoneId || EMPTY_STRING,
        phoneNumber: contactInfo.phoneNumber || EMPTY_STRING,
        phoneType: contactInfo.phoneType || EMPTY_STRING,
        phoneTypeDesc: contactInfo.phoneTypeDesc || EMPTY_STRING,
        extension: contactInfo.extension || EMPTY_STRING,
        primary: contactInfo.primary || STRING_NO,
        callTimeType: contactInfo.callTimeType || EMPTY_STRING,
        callTimeTypeDesc: contactInfo.callTypeDesc || EMPTY_STRING,
        active: contactInfo.active || STRING_NO,
        instructions: contactInfo.note || EMPTY_STRING,
        isNDNCOpted: contactInfo.isNDNCOpted || STRING_NO,
        isTrnsMsgAllowed:
          !contactInfo.isTrnsMsgAllowed ||
            contactInfo.isTrnsMsgAllowed === STRING_NO
            ? STRING_NO
            : STRING_YES,
        isMktgMsgAllowed:
          !contactInfo.isMktgMsgAllowed ||
            contactInfo.isMktgMsgAllowed === STRING_NO
            ? STRING_NO
            : STRING_YES,
        cancelable: false,
        isEditEnabled: isEditEnabled,
        textMarketingOffersOri:
          !contactInfo.isMktgMsgAllowed || contactInfo.isMktgMsgAllowed === "N"
            ? false
            : true,
        textMessageReminderOri:
          !contactInfo.isTrnsMsgAllowed || contactInfo.isTrnsMsgAllowed === "N"
            ? false
            : true,
        phoneNumberOri: contactInfo.phoneNumber,
        primaryFlag: contactInfo.primary || STRING_NO,
      };

      if (contactInfo.lastModifiedDate) {
        iteratedContact.lastModifiedDate = contactInfo.lastModifiedDate;
      }
      if (contactInfo.lastModifiedBy) {
        iteratedContact.lastModifiedBy = contactInfo.lastModifiedBy;
      }
      if (contactInfo.createdSource) {
        iteratedContact.createdSource = contactInfo.createdSource;
      }
      if (contactInfo.createdDate) {
        iteratedContact.createdDate = contactInfo.createdDate;
      }

      if (contactInfo.createdBy) {
        iteratedContact.createdBy = contactInfo.createdBy;
      }

      if (contactInfo.lastModifiedSource) {
        iteratedContact.lastModifiedSource = contactInfo.lastModifiedSource;
      }

      if (contactInfo.entPhoneId) {
        iteratedContact.entPhoneId = contactInfo.entPhoneId;
      }

      contactInformation.push(iteratedContact);
    });

    return contactInformation;
  } catch (error: any) {
    console.log("Error in buildContactInformation: ", error?.message);
    return [];
  }
};

const buildReferenceDetails = (
  information: any[],
  verificationExipryDays: string,
  headerInfo: any
): ReferenceInformation[] => {
  const referenceInformation: ReferenceInformation[] = [];
  const overrideObject =
    headerInfo &&
    headerInfo?.verificationDocuments?.length &&
    headerInfo?.verificationDocuments?.find(
      (element: any) => element.verifyType == "REFERENCES"
    );
  try {
    information && information?.forEach((referenceInfo: any) => {
      const template = {
        referenceInfo: {
          relativeFirstName: EMPTY_STRING,
          relativeLastName: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          bestTimeToCall: EMPTY_STRING,
          relationShip: EMPTY_STRING,
          doNotCall: false,
          isEdited: false,
          relationshipTypeDesc: EMPTY_STRING,
          referenceName: EMPTY_STRING
        },
        referenceAddressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          isEdited: false,
        },
        verified: false,
        verifiedDate: EMPTY_STRING,
        active: false,
        referenceDetailsId: EMPTY_STRING,
        decisionEngineId: EMPTY_STRING,
        isEditEnabled: false,
        verifyCode: EMPTY_STRING,
        verifiedCV: false,
        verifiedBy: EMPTY_STRING,
        verifiedOn: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        isCancellable: false
      };

      // Set reference info fields
      template.referenceInfo.relativeFirstName =
        referenceInfo.firstName || EMPTY_STRING;
      template.referenceInfo.referenceName = referenceInfo.firstName || EMPTY_STRING,
        template.referenceInfo.relativeLastName =
        referenceInfo.lastName || EMPTY_STRING;
      template.referenceInfo.phoneNumber =
        referenceInfo.phoneNumber || EMPTY_STRING;
      template.referenceInfo.bestTimeToCall =
        referenceInfo.bestTimeToCall || EMPTY_STRING;
      template.referenceInfo.relationShip =
        referenceInfo.relationshipType || EMPTY_STRING;
      template.referenceInfo.relationshipTypeDesc =
        referenceInfo.relationshipTypeDesc || EMPTY_STRING;
      template.referenceInfo.doNotCall =
        referenceInfo.doNotCall == 'Y' || false;

      // Set reference address info fields
      template.referenceAddressInfo.addressLine1 =
        referenceInfo.addressLine1 || EMPTY_STRING;
      template.referenceAddressInfo.addressLine2 =
        referenceInfo.addressLine2 || EMPTY_STRING;

      // Format postal code
      template.referenceAddressInfo.zip =
        referenceInfo.postalCode?.trim()?.length === 9
          ? referenceInfo.postalCode.slice(0, 5) +
          "-" +
          referenceInfo.postalCode.slice(5)
          : referenceInfo.postalCode?.trim() || EMPTY_STRING;

      template.referenceAddressInfo.city = referenceInfo.city || EMPTY_STRING;
      template.referenceAddressInfo.state =
        referenceInfo.state && referenceInfo.state !== STRING_ZERO
          ? referenceInfo.state
          : EMPTY_STRING;

      // Set additional fields
      template.verifiedDate = validateVerificationDate(
        referenceInfo.verifiedDate,
        verificationExipryDays
      );
      template.active = referenceInfo.active || EMPTY_STRING;
      template.referenceDetailsId =
        referenceInfo.personalReferenceId || EMPTY_STRING;
      template.isEditEnabled = false;

      console.log("reference headerInfo", headerInfo, overrideObject);

      // Set header info fields
      template.decisionEngineId = headerInfo
        ? headerInfo.decisionId || EMPTY_STRING
        : EMPTY_STRING;
      template.verifyCode = headerInfo
        ? headerInfo.verifyCode || EMPTY_STRING
        : EMPTY_STRING;
      template.verifiedBy = overrideObject
        ? overrideObject?.lastModifiedBy
          ? overrideObject.lastModifiedBy
          : EMPTY_STRING
        : EMPTY_STRING;
      template.verifiedOn = overrideObject
        ? overrideObject?.lastModifiedDate
          ? moment(overrideObject?.lastModifiedDate).format("MM/DD/YYYY")
          : EMPTY_STRING
        : EMPTY_STRING;
      template.verifiedSource = overrideObject
        ? overrideObject.clientId || EMPTY_STRING
        : EMPTY_STRING;
      template.verifiedCV = overrideObject
        ? overrideObject?.verificationStatus == "VERIFIED"
        : false;
      template.verified = overrideObject
        ? overrideObject?.verificationStatus == "VERIFIED"
        : false;

      referenceInformation.push(template);
    });

    console.log('Initial Reference Info', referenceInformation);
    !information && referenceInformation?.length == 0 ?
      referenceInformation.push({
        referenceInfo: {
          relativeFirstName: EMPTY_STRING,
          relativeLastName: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          bestTimeToCall: EMPTY_STRING,
          relationShip: EMPTY_STRING,
          doNotCall: false,
          isEdited: false,
          relationshipTypeDesc: EMPTY_STRING,
          referenceName: EMPTY_STRING
        },
        referenceAddressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          isEdited: false,
        },
        verified: overrideObject
          ? overrideObject?.verificationStatus == "VERIFIED"
          : false,
        verifiedDate: EMPTY_STRING,
        active: true,
        referenceDetailsId: EMPTY_STRING,
        decisionEngineId: headerInfo
          ? headerInfo.decisionId || EMPTY_STRING
          : EMPTY_STRING,
        isEditEnabled: false,
        verifyCode: headerInfo
          ? headerInfo.verifyCode || EMPTY_STRING
          : EMPTY_STRING,
        verifiedCV: overrideObject
          ? overrideObject?.verificationStatus == "VERIFIED"
          : false,
        verifiedBy: overrideObject
          ? overrideObject?.lastModifiedBy
            ? overrideObject.lastModifiedBy
            : EMPTY_STRING
          : EMPTY_STRING,
        verifiedOn: overrideObject
          ? overrideObject?.lastModifiedDate
            ? moment(overrideObject?.lastModifiedDate).format("MM/DD/YYYY")
            : EMPTY_STRING
          : EMPTY_STRING,
        verifiedSource: overrideObject
          ? overrideObject.clientId || EMPTY_STRING
          : EMPTY_STRING,
        isCancellable: false
      })
      : null
    referenceInformation && referenceInformation.length > 1 && referenceInformation.sort((a, b) => Number(a.referenceDetailsId) - Number(b.referenceDetailsId));
    console.log('Final Reference Info', referenceInformation);
  } catch (error: any) {
    console.log("Error in buildReferenceDetails: ", error?.message);
  } finally {
    if (!referenceInformation.length) {
      referenceInformation.push({ ...REFERENCE_INFO_TEMPLATE });
    }
    return referenceInformation;
  }
};

const buildTaxExemptInformation = (
  taxExemptResponse: GetCustomerResponse
): TaxExemptInformation => {
  const taxExemptInformation: TaxExemptInformation = { ...TAX_EXEMPT_TEMPLATE };
  try {
    taxExemptInformation.federalTaxId =
      taxExemptResponse.federalTaxId || EMPTY_STRING;
    taxExemptInformation.status =
      taxExemptResponse.taxExemptStatus || EMPTY_STRING;
    taxExemptInformation.effectiveDate = taxExemptResponse.submittedDate
      ? formatDate(taxExemptResponse.submittedDate)
      : EMPTY_STRING;
  } catch (error: any) {
    console.log("Error in buildTaxExemptInformation: ", error?.message);
  } finally {
    return taxExemptInformation;
  }
};

const buildAddressInfo = (addressResponse: any): AddressDetails[] => {
  try {
    const addressDetails: AddressDetails[] = [];

    addressResponse?.forEach((detail: any, index: number) => {
      let iteratedAddress = { ...ADDRESS_INFO_TEMPLATE };
      iteratedAddress.addressId = detail.addressId;
      iteratedAddress.agreementId = detail.agreementId;
      iteratedAddress.agreementNumber = detail.agreementNumber;
      iteratedAddress.storeNumber = detail.storeNumber;
      iteratedAddress.addressType = detail.addressType;
      iteratedAddress.addressTypeDesc = detail.addressTypeDesc;
      iteratedAddress.addressLine1 = !!detail.addressLine1 ? detail.addressLine1 : '';
      iteratedAddress.addressLine2 = !!detail.addressLine2 ? detail.addressLine2 : '';
      iteratedAddress.city = detail.city;
      iteratedAddress.state = detail.state;
      iteratedAddress.lastModifiedBy = detail.lastModifiedBy;
      iteratedAddress.lastModifiedDate = detail.lastModifiedDate;
      iteratedAddress.postalCode = detail.postalCode?.trim() !== '-'
        ? `${detail.postalCode}${detail.plus4 ? '-' + detail.plus4 : ''}`
        : '';

      addressDetails.push(iteratedAddress);
    });

    return addressDetails;
  } catch (error: any) {
    console.log("Error in buildAddressInformation: ", error?.message);
    return [];
  }
};

const buildEmployerInformation = (
  employerResponse: any,
  isEditEnabled: boolean,
  headerInfo: any
) => {
  try {
    const employerDetails: EmployerDetails[] = [];
    employerResponse && employerResponse?.forEach((details: any) => {
      let employerInformation = {
        basicInfo: {
          companyName: EMPTY_STRING,
          employmentYears: EMPTY_STRING,
          phoneNumber: EMPTY_STRING,
          doNotCallEnabled: false,
          isEdited: false,
        },
        incomeVerification: {
          paydayFrequency: EMPTY_STRING,
          dayPaid: EMPTY_STRING,
          takeHomePay: EMPTY_STRING,
          isEdited: false,
          payDayFreqDesc: EMPTY_STRING,
          daysPaidDesc: EMPTY_STRING,
          incomeSource: EMPTY_STRING
        },
        addressInfo: {
          addressLine1: EMPTY_STRING,
          addressLine2: EMPTY_STRING,
          zip: EMPTY_STRING,
          city: EMPTY_STRING,
          state: EMPTY_STRING,
          doNotVisit: false,
          isEdited: false,
        },
        verified: false,
        active: true,
        verifiedDate: EMPTY_STRING,
        employerDetailsId: null,
        overrideEnabled: false,
        isEditEnabled: false,
        verifiedBy: EMPTY_STRING,
        decisionEngineId: EMPTY_STRING,
        verifiedSource: EMPTY_STRING,
        verifyCode: EMPTY_STRING,
        isCancellable: false
      };
      employerInformation.basicInfo.companyName = details?.employerName ? details?.employerName : EMPTY_STRING;
      employerInformation.basicInfo.employmentYears =
        details?.lengthOfEmploymentYears ? details?.lengthOfEmploymentYears : EMPTY_STRING;
      employerInformation.basicInfo.phoneNumber = details?.employerPhoneNumber ? details?.employerPhoneNumber : EMPTY_STRING;
      employerInformation.basicInfo.doNotCallEnabled =
        details?.doNotCall === STRING_YES ? true : false;

      employerInformation.incomeVerification.paydayFrequency =
        details?.employerPayschedule && details?.employerPayschedule != '0' ? details?.employerPayschedule : '';
      employerInformation.incomeVerification.dayPaid = details?.daysPaid && details?.daysPaid != '0' ? details?.daysPaid : '';
      employerInformation.incomeVerification.takeHomePay = details?.takeHomePay == '0' || details?.takeHomePay == 0 ? '0.00' : details?.takeHomePay;
      employerInformation.incomeVerification.payDayFreqDesc =
        details?.employerPayscheduleDesc;
      employerInformation.incomeVerification.daysPaidDesc =
        details?.daysPaidDesc;
      employerInformation.incomeVerification.incomeSource =
        details?.incomeSourceType;

      employerInformation.addressInfo.addressLine1 = details?.addressLine1;
      employerInformation.addressInfo.addressLine2 = details?.addressLine2;
      employerInformation.addressInfo.zip = details?.postalCode;
      employerInformation.addressInfo.city = details?.city;
      employerInformation.addressInfo.state = details?.state;
      employerInformation.addressInfo.doNotVisit =
        details?.doNotVisit === STRING_YES ? true : false;
      employerInformation.active = details?.active;
      employerInformation.employerDetailsId = details?.employerReferenceId;
      employerInformation.isEditEnabled = true;
      employerInformation.decisionEngineId = headerInfo
        ? headerInfo?.decisionId
        : EMPTY_STRING;
      employerInformation.verifyCode = headerInfo
        ? headerInfo?.verifyCode
          ? headerInfo.verifyCode
          : EMPTY_STRING
        : EMPTY_STRING;
      const overrideObject =
        headerInfo &&
        headerInfo?.verificationDocuments?.length &&
        headerInfo?.verificationDocuments?.find(
          (element: any) => element.verifyType == "EMPLOYMENT"
        );
      employerInformation.verified = overrideObject
        ? overrideObject?.verificationStatus == "VERIFIED"
        : false;
      employerInformation.overrideEnabled = overrideObject
        ? overrideObject?.override == "Y"
        : false;
      employerInformation.verifiedDate = overrideObject
        ? overrideObject?.lastModifiedDate
          ? moment(overrideObject?.lastModifiedDate).format("MM/DD/YYYY")
          : EMPTY_STRING
        : EMPTY_STRING;
      employerInformation.verifiedBy = overrideObject
        ? overrideObject?.lastModifiedBy
          ? overrideObject.lastModifiedBy
          : EMPTY_STRING
        : EMPTY_STRING;
      employerInformation.verifiedSource = overrideObject
        ? overrideObject?.clientId
          ? overrideObject.clientId
          : EMPTY_STRING
        : EMPTY_STRING;
      employerDetails.push(employerInformation);
    });
    const overrideObject =
      headerInfo &&
      headerInfo?.verificationDocuments?.length &&
      headerInfo?.verificationDocuments?.find(
        (element: any) => element.verifyType == "EMPLOYMENT"
      );
    console.log('EmployerDetails in API Handler', employerDetails)
    employerDetails?.length == 0 ? (
      employerDetails.push({
        basicInfo: {
          companyName: employerDetails?.[0]?.basicInfo.companyName || EMPTY_STRING,
          employmentYears: employerDetails?.[0]?.basicInfo.employmentYears || EMPTY_STRING,
          phoneNumber: employerDetails?.[0]?.basicInfo.phoneNumber || EMPTY_STRING,
          doNotCallEnabled: false,
          isEdited: false,
        },
        incomeVerification: {
          paydayFrequency: employerDetails?.[0]?.incomeVerification.paydayFrequency || EMPTY_STRING,
          dayPaid: employerDetails?.[0]?.incomeVerification.dayPaid || EMPTY_STRING,
          takeHomePay: employerDetails?.[0]?.incomeVerification.takeHomePay || '0.00',
          isEdited: false,
          payDayFreqDesc: employerDetails?.[0]?.incomeVerification.payDayFreqDesc || EMPTY_STRING,
          daysPaidDesc: employerDetails?.[0]?.incomeVerification.daysPaidDesc || EMPTY_STRING,
          incomeSource: employerDetails?.[0]?.incomeVerification.daysPaidDesc || EMPTY_STRING,
        },
        addressInfo: {
          addressLine1: employerDetails?.[0]?.addressInfo.addressLine1 || EMPTY_STRING,
          addressLine2: employerDetails?.[0]?.addressInfo.addressLine2 || EMPTY_STRING,
          zip: employerDetails?.[0]?.addressInfo.zip || EMPTY_STRING,
          city: employerDetails?.[0]?.addressInfo.city || EMPTY_STRING,
          state: employerDetails?.[0]?.addressInfo.state || EMPTY_STRING,
          doNotVisit: false,
          isEdited: false,
        },
        verified: overrideObject
          ? overrideObject?.verificationStatus == "VERIFIED"
          : false,
        active: true,
        verifiedDate: overrideObject
          ? overrideObject?.lastModifiedDate
            ? moment(overrideObject?.lastModifiedDate).format("MM/DD/YYYY")
            : EMPTY_STRING
          : EMPTY_STRING,
        employerDetailsId: null,
        overrideEnabled: overrideObject
          ? overrideObject?.override == "Y"
          : false,
        isEditEnabled: true,
        verifiedBy: overrideObject
          ? overrideObject?.lastModifiedBy
            ? overrideObject.lastModifiedBy
            : EMPTY_STRING
          : EMPTY_STRING,
        decisionEngineId: headerInfo
          ? headerInfo?.decisionId
          : EMPTY_STRING,
        verifiedSource: overrideObject
          ? overrideObject?.clientId
            ? overrideObject.clientId
            : EMPTY_STRING
          : EMPTY_STRING,
        verifyCode: headerInfo
          ? headerInfo?.verifyCode
            ? headerInfo.verifyCode
            : EMPTY_STRING
          : EMPTY_STRING,
        isCancellable: false
      })
    ) : null
    employerDetails && employerDetails.length > 1 && employerDetails.sort((a, b) => Number(a.employerDetailsId) - Number(b.employerDetailsId));
    return [employerDetails[0]];
  } catch (error: any) {
    console.log("Error in buildEmployerInformation: ", error?.message);
    return [];
  }
};

const buildIdentityDetails = (
  identityDetailsResponse: CustomerBasicInformation,
  headerInfo: any
) => {
  try {
    const identityDetailsInfo: IdentityDetailsInfo = IDENTITY_DETAILS;
    identityDetailsInfo.governmentId =
      identityDetailsResponse.govtIdDecr || EMPTY_STRING;
    identityDetailsInfo.governmentIdType =
      identityDetailsResponse.governmentIdType || EMPTY_STRING;
    identityDetailsInfo.issuedState =
      identityDetailsResponse.governmentIdIssuedState || EMPTY_STRING;
    (identityDetailsInfo.govtIdDecrpted =
      identityDetailsResponse.govtIdDecr || EMPTY_STRING),
      (identityDetailsInfo.decisionEngineId = headerInfo
        ? headerInfo.decisionId
        : EMPTY_STRING);
    identityDetailsInfo.verifyCode = headerInfo
      ? headerInfo?.verifyCode
        ? headerInfo.verifyCode
        : EMPTY_STRING
      : EMPTY_STRING;
    const overrideObject =
      headerInfo &&
      headerInfo?.verificationDocuments?.find(
        (element: any) => element.verifyType == "ID"
      );
    identityDetailsInfo.verified = overrideObject
      ? overrideObject?.verificationStatus == "VERIFIED"
      : false;
    identityDetailsInfo.overrideEnabled = overrideObject
      ? overrideObject?.override == "Y"
      : false;
    identityDetailsInfo.verifiedDate = overrideObject
      ? overrideObject?.lastModifiedDate
        ? moment(overrideObject?.lastModifiedDate).format("MM/DD/YYYY")
        : EMPTY_STRING
      : EMPTY_STRING;
    identityDetailsInfo.verifiedBy = overrideObject
      ? overrideObject?.lastModifiedBy
        ? overrideObject.lastModifiedBy
        : EMPTY_STRING
      : EMPTY_STRING;
    identityDetailsInfo.verifiedSource = overrideObject
      ? overrideObject?.clientId
        ? overrideObject.clientId
        : EMPTY_STRING
      : EMPTY_STRING;

    return identityDetailsInfo;
  } catch (e: any) {
    console.log("Error In Identity Details Formation", e);
    return IDENTITY_DETAILS;
  }
};

const getData = (key: string, iv: string, data: string) => {
  const decipher = createDecipheriv("aes-256-cbc", key, iv);
  let result: any = decipher.update(data, "hex", "utf8");
  result += decipher.final("utf8");
  return result;
};

export {
  buildBasicInformation,
  buildCommunicationPreference,
  buildSkipStolenHardInfo,
  buildInformationPageDropdowns,
  buildVehicleinformation,
  buildResidenceInformation,
  buildContactInformation,
  buildReferenceDetails,
  buildTaxExemptInformation,
  buildAddressInfo,
  buildEmployerInformation,
  buildIdentityDetails,
};
