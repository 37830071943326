/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Grid,
  RACButton,
  RACDatePicker,
  RACSelect,
  RACTextbox,
  RACTooltip,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { ResidenceInfoProps } from "../../../interface/globalInterface";
import { formatMobileNumber, formatText, handleFileChange, updateResidenceInfo } from "../Utils";
import { DynamicAlertPopup } from "../DynamicAlertPopup";
import { DocumentViewer } from "../DocumentViewer";

import { MODULE_NAME, SUPPORTED_FILE_TYPE } from "../../../constants/constants";
import { ReactComponent as BlueInfoIcon } from "../../../../assets/images/blue-info-icon.svg";
import { ReactComponent as ViewEyeIcon } from '../../../assets/images/ViewPdfIcon.svg';

import { ReactComponent as PDFIcon } from "./../../../../assets/images/pdfIcon.svg";



import { ReactComponent as ContactIcon } from '../../../../CustomerRefactor/assets/images/ContactIcon.svg';



import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";

import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";
export default function RentalResidenceInfo({ value, setterData }: ResidenceInfoProps) {
  const classes = globalStyles();
  const { residenceEditClicked, residenceInfoCopy, residenceTenureTypeOptions, configurations, verificationDocuments, residenceDoc, currentRole, setresidenceVerificationToggle, setResidenceDocumentCopy, residenceDocumentCopy, setResidenceDoc, setIsResidenceDocDeleted } = useContext(UpdateCustomerContext)
  const fileInputRef = useRef<any>(null);
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [errorMessge, setErrorMessage] = useState<string>('');
  const [showDocument, setShowDocument] = useState<boolean>(false);
  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState<boolean>(false);


  useEffect(() => {
    if (residenceDocumentCopy) {
      setShowDocument(true)
    } else {
      setShowDocument(false);
    }
  }, [residenceDocumentCopy])
  useEffect(() => {
    if (verificationDocuments && !residenceDoc) {
      console.log("VerificationDocuments", verificationDocuments);
      const resideceDocument: any = verificationDocuments?.filter(
        (doc: { refCode: any }) => doc.refCode === "RESDOC"
      );
      // Find the most recent one
      if (resideceDocument && resideceDocument?.length > 0) {
        const recentIncomeDocument: any = resideceDocument?.reduce(
          (latest: any, current: any) => {
            return new Date(latest.createdDate) > new Date(current.createdDate)
              ? latest
              : current;
          }
        );
        console.log("recentResidenceDocument", recentIncomeDocument?.file);
        if (recentIncomeDocument?.file) {
          setResidenceDocumentCopy(recentIncomeDocument?.file);
          setShowDocument(true);
        }
      }


    }

  }, [residenceDocumentCopy])
  // Function to trigger file input click event
  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  const handleFile = async (event: any) => {
    console.log('File details', event.target.files);
    if (event.target.files && event.target.files?.[0]?.size) {

      const fileSizeInBytes = event.target.files?.[0]?.size;
      let fileSize: any = fileSizeInBytes;
      let sizeUnit = "bytes";

      if (fileSizeInBytes >= 1024) {
        fileSize = fileSizeInBytes / 1024;
        sizeUnit = "KB";
      }

      if (fileSize >= 1024) {
        fileSize = fileSize / 1024;
        sizeUnit = "MB";
      }

      if (fileSize >= 1024) {
        fileSize = fileSize / 1024;
        sizeUnit = "GB";
      }

      // Round the file size to 2 decimal places
      fileSize = fileSize.toFixed(2);
      if (SUPPORTED_FILE_TYPE.includes(event.target.files[0]?.type)) {
        if (sizeUnit !== "KB") {
          if ((sizeUnit == "MB" && Number(fileSize) > 2) || sizeUnit == "GB") {
            setMessagePopup(true);
            setErrorMessage("Please upload a file smaller than 2MB.");
          } else {
            const base64Data: any = await handleFileChange(event);
            setShowDocument(true);
            console.log("File Base64Data", base64Data);
            let data: any = base64Data?.split(",");
            if (data?.[0]?.includes("application/pdf")) {
              data = data[1];
            } else {
              data = base64Data;
            }
            setResidenceDocumentCopy(data);
          }
        } else {
          const base64Data: any = await handleFileChange(event);
          setShowDocument(true);
          console.log("File Base64Data", base64Data);
          let data: any = base64Data?.split(",");
          if (data?.[0]?.includes("application/pdf")) {
            data = data[1];
          } else {
            data = base64Data;
          }
          setResidenceDocumentCopy(data);
        }
      } else {
        setMessagePopup(true);
        setErrorMessage("Invalid file format. Please upload a PDF, JPEG, or PNG.");
      }
    }
  }


  return (

    <Grid container>
      <Grid container md={12} spacing={2} className={`${classes.mt16pxRem}`}>
        {configurations?.EnableCustomerVerificationPhaseTwo ? <Grid container md={12} id='residenceRentUploadDocument'>

          <Grid container md={12} spacing={2}>
            <Grid item md={6}>
              <span className={classes.residenceOption1Header}>Option 1: Rental Information</span>
              <RACTooltip
                placement={"top"}
                className={classes.tooltipcss}
                classes={{
                  tooltip: classes.toolTipStyle4,
                  arrow: classes.arrowStyle1,
                }}
                title={
                  <Grid
                    className={`${classes.textCenter} ${classes.displayFlex} ${classes.justifyContentCenter} ${classes.flexDirectionColumn} ${classes.width100p}`}
                  >
                    <Typography
                      component={`label`}
                      className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
                    >
                      Rental Input
                    </Typography>
                    <Typography
                      component={`label`}
                      className={`${classes.fontSize14px}`}
                    >
                      Complete the verification by contacting the number provided
                      and confirming the customer lives there
                    </Typography>

                  </Grid>
                }
              >
                <BlueInfoIcon
                  style={{
                    height: "15px",
                    marginLeft: "0px",
                    marginTop: "3px",
                  }}
                />
              </RACTooltip>

            </Grid>
            <Grid item md={6} style={{ padding: '11px 0px 0px 42px' }}>
              <span className={classes.residenceOption1Header}>Option 2: Document Upload</span>
              <RACTooltip
                placement={"top"}
                className={classes.tooltipcss}
                classes={{
                  tooltip: classes.toolTipStyle4,
                  arrow: classes.arrowStyle1,
                }}
                title={
                  <Grid
                    className={`${classes.textCenter} ${classes.displayFlex} ${classes.justifyContentCenter} ${classes.flexDirectionColumn} ${classes.width100p}`}
                  >
                    <Typography
                      component={`label`}
                      className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
                    >
                      Document Upload
                    </Typography>
                    <Typography
                      component={`label`}
                      className={`${classes.fontSize14px}`}
                    >
                      Complete the verification by ensuring the customer name and address on the document
                      match what the customer provided

                    </Typography>

                  </Grid>
                }
              >
                <BlueInfoIcon
                  style={{
                    height: "15px",
                    marginLeft: "0px",
                    marginTop: "3px",
                  }}
                />
              </RACTooltip>
            </Grid>
          </Grid>

          <Grid item container md={12} spacing={2} style={{ marginTop: '32px' }}>
            <Grid item md={2} >
              <RACSelect
                inputLabel={"Time at Current Address"}
                name="timeatCurrentAddress"
                required={true}
                inputLabelClassName={classes.fontSize13px}
                isDisabled={!residenceEditClicked || residenceDocumentCopy}
                defaultValue={
                  residenceEditClicked ? value.rentalDetails.residenceTenureType : residenceInfoCopy?.rentalDetails.residenceTenureType
                }
                options={residenceTenureTypeOptions}
                onChange={(e: any) => {
                  updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                    rentalDetails: {
                      ...setterData.residenceInfo.rentalDetails,
                      residenceTenureType: e.target.value
                    }
                  }); setresidenceVerificationToggle(false)
                }}
                errorMessage={
                  value?.residenceInfoError?.residenceTenureTypeError ? "Required" : ''
                }
              />
            </Grid>
            <Grid item md={2} >
              <RACTextbox
                disabled={!residenceEditClicked || residenceDocumentCopy}
                inputLabelClassname={classes.fontSize13px}
                required={true}
                type={"text"}
                inputlabel="Landlord’s / Apartment Name "
                OnChange={(e: any) => {
                  updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                    rentalDetails: {
                      ...setterData.residenceInfo.rentalDetails,
                      landlordOrAptFirstName: formatText(e.target.value).replace(/[^A-Z'.;’ a-z]/gi, '')
                    }
                  });
                  setresidenceVerificationToggle(false)
                }
                }
                maxlength={30}
                value={residenceEditClicked ? value.rentalDetails.landlordOrAptFirstName : residenceInfoCopy?.rentalDetails.landlordOrAptFirstName}
                errorMessage={
                  value?.residenceInfoError?.landlordOrAptFirstNameError ? "Required" : ''
                }
              />
            </Grid>
            <Grid item md={3} >
              <RACTextbox
                disabled={!residenceEditClicked || residenceDocumentCopy}
                isCurrency={false}
                required={true}
                inputLabelClassname={classes.fontSize13px}
                className={classes.inputTxt}
                inputlabel={"Landlord/Leasing Office Number "}
                OnChange={(e: any) => {
                  updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                    verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                    rentalDetails: { ...setterData.residenceInfo.rentalDetails, landlordOrAptPhoneNumber: e.target.value }
                  }); setresidenceVerificationToggle(false)
                }}
                maxlength={14}
                value={residenceEditClicked ? formatMobileNumber(value.rentalDetails?.landlordOrAptPhoneNumber) : formatMobileNumber(residenceInfoCopy?.rentalDetails?.landlordOrAptPhoneNumber)}
                errorMessage={
                  value?.residenceInfoError?.landlordOrAptPhoneNumberError ? "Required" : ''
                }
              />
            </Grid>

            <span className={classes.residenceRentalOrStyle}>or</span>

            <Grid
              item
              md={3}
              className={`${classes.pt0} ${classes.displayFlex} ${classes.flexDirectionColumn}`}
            >
              <Grid>
                <Typography component={"label"} style={{ display: 'flex', fontSize: '13px' }}>
                  Documents{" "}
                  <Typography component={"span"} style={{ color: "red", display: 'flex' }}>
                    *
                    <RACTooltip
                      placement={"top"}
                      className={classes.tooltipcss}
                      classes={{
                        tooltip: classes.toolTipStyle4,
                        arrow: classes.arrowStyle1,
                      }}
                      title={
                        <Grid
                          className={`${classes.textCenter} ${classes.displayFlex} ${classes.justifyContentCenter} ${classes.flexDirectionColumn} ${classes.width100p}`}
                        >
                          <Typography
                            component={`label`}
                            className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
                          >
                            Acceptable Documents
                          </Typography>
                          <Typography
                            component={`label`}
                            className={`${classes.fontSize14px}`}
                          >
                            Any document that can properly associate the customer with the provided address
                          </Typography>
                          <Typography component={`label`}>
                            (e.g., Utility Bill, Mortgage Documents, Bank Statement, etc)
                          </Typography>
                        </Grid>
                      }
                    >
                      <BlueInfoIcon
                        style={{
                          height: "15px",
                          marginLeft: "0px",
                          marginTop: "3px",
                        }}
                      />
                    </RACTooltip>
                  </Typography>
                </Typography>

              </Grid>
              {showDocument ? (
                <Grid className={`${classes.displayFlex}`}>
                  <Typography
                    component={"label"}
                    className={residenceEditClicked ? `${classes.fontPrimaryColor} ${classes.p10px} ${classes.fontOpensansBold} ${classes.fontSize14px}` : `${classes.fontDisabledColor} ${classes.p10px} ${classes.fontOpensansBold} ${classes.fontSize14px}`}
                  >
                    <PDFIcon style={{ float: "left", display: 'inline-block', width: 'auto', marginRight: '5px' }}></PDFIcon>

                    Proof of Residence.pdf
                  </Typography>
                  <Typography
                    component={"label"}
                    className={residenceEditClicked ? `${classes.fontPrimaryColor} ${classes.pointer} ${classes.p10px} ${classes.fontOpensansBold} ${classes.font16}` : `${classes.fontDisabledColor} ${classes.pointer} ${classes.p10px} ${classes.fontOpensansBold} ${classes.font16}`}
                    onClick={() => {
                      if (residenceEditClicked) {
                        setShowDocument(false);
                        setResidenceDocumentCopy("");
                        setIsResidenceDocDeleted(true);
                      }
                    }}
                  >
                    X
                  </Typography>
                  <Typography
                    onClick={() => {
                      if (residenceEditClicked) {
                        setOpenDocument(true);
                      }
                    }}
                    className={`${classes.viewPdfBtnColor} ${classes.pointer} ${classes.displayFlex} ${classes.justifyContentSpaceAround} ${classes.font16} ${classes.fontOpensansBold}`}
                  >
                    <span
                      style={residenceEditClicked ? { cursor: 'pointer' } : { cursor: 'default' }}
                      onClick={() => {
                        if (residenceEditClicked) {
                          setOpenDocument(true);
                        }
                      }}
                    >
                      <ViewEyeIcon style={{ width: '60px', height: '28px', marginLeft: '4px' }} />
                    </span>
                  </Typography>
                </Grid>
              ) : (
                <RACButton
                  variant="contained"
                  color="primary"
                  id="uploadDocument"
                  disabled={
                    !residenceEditClicked
                  }
                  onClick={() => {
                    handleButtonClick();
                  }}
                  className={`${classes.resUploadImageBtn} ${classes.pointer}`}
                >
                  Upload
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFile}
                    accept=".png,.jpeg,.jpg,.pdf"
                    style={{ display: "none" }}
                  />
                </RACButton>
              )}
            </Grid>

          </Grid>


        </Grid> :
          <React.Fragment>
            <Grid item md={2}>
              <RACTextbox
                required={true}
                type={"text"}
                disabled={!residenceEditClicked}
                inputlabel="Landlord's First Name"
                OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                  rentalDetails: {
                    ...setterData.residenceInfo.rentalDetails,
                    landlordsFirstName: formatText(e.target.value).replace(/[^A-Z'.;’ a-z]/gi, '')
                  }
                })}
                maxlength={30}
                value={residenceEditClicked ? value.rentalDetails.landlordsFirstName : residenceInfoCopy?.rentalDetails.landlordsFirstName}

                errorMessage={
                  value?.residenceInfoError?.landlordsFirstNameError ? "Required" : ''
                }
              />
            </Grid>
            <Grid item md={2}>
              <RACTextbox
                isCurrency={false}
                required={true}
                type={"text"}
                disabled={!residenceEditClicked}
                inputlabel="Landlord's Last Name"
                OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                  verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                  rentalDetails: {
                    ...setterData.residenceInfo.rentalDetails,
                    landlordsLastName: formatText(e.target.value),

                  }
                })}
                maxlength={30}
                value={residenceEditClicked ? value.rentalDetails.landlordsLastName : residenceInfoCopy?.rentalDetails.landlordsLastName}
                errorMessage={
                  value?.residenceInfoError?.landlordsLastNameError ? "Required" : ''
                }
              />
            </Grid >
            <Grid item md={2}>
              <RACTextbox
                isCurrency={false}
                required={true}
                type={"text"}
                disabled={!residenceEditClicked}
                inputlabel="Landlord’s Phone Number"
                name={`llnumber`}
                OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                  verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                  rentalDetails: { ...setterData.residenceInfo.rentalDetails, landlordsPhoneNumber: e.target.value }
                })}
                maxlength={14}
                value={residenceEditClicked ? formatMobileNumber(value.rentalDetails?.landlordsPhoneNumber) : formatMobileNumber(residenceInfoCopy?.rentalDetails?.landlordsPhoneNumber)}
                errorMessage={
                  value?.residenceInfoError?.landlordsPhoneNumberError ? "Required" : ''
                }
              />
            </Grid >
            <Grid item md={2}>
              <RACTextbox
                isCurrency={true}
                type={"number"}
                digitFormat={"currency"}
                disabled={!residenceEditClicked}
                inputlabel="Payment Amount"
                name={`llamount`}
                OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                  verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                  rentalDetails: { ...setterData.residenceInfo.rentalDetails, paymentAmount: e.target.value }
                })}
                maxlength={10}
                value={residenceEditClicked ? value.rentalDetails.paymentAmount ? value.rentalDetails?.paymentAmount : '' : residenceInfoCopy?.rentalDetails.paymentAmount ? residenceInfoCopy?.rentalDetails?.paymentAmount : ''}
                className={classes.currentTextBoxStyle}
                dollarTextClassName={classes.customerDollerInputFiled}
              />
            </Grid >
            <Grid item md={2}>
              <RACDatePicker
                disabled={!residenceEditClicked}
                value={residenceEditClicked ? value.rentalDetails.moveInDate : residenceInfoCopy?.rentalDetails?.moveInDate}
                label="Move in Date"
                name="lldateMove"
                onChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
                  verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                  rentalDetails: { ...setterData.residenceInfo.rentalDetails, moveInDate: e }
                })}
              />
            </Grid >
          </React.Fragment >
        }
        {
          messagePopup ? (
            <DynamicAlertPopup
              alertType="ERROR"
              alertMessage={errorMessge}
              closeIcon={true}
              onCloseFn={() => {
                setMessagePopup(false);

              }}
            />
          ) : null}

        {
          openDocument ? (
            <DocumentViewer
              base64String={residenceDocumentCopy}
              onCloseFn={() => {
                setOpenDocument(false);
              }}
              popupTitle="Proof of Residence"
            />
          ) : null}

        {
          twoFactorAuth ? (
            <SecondFactor
              setTwoFactorCancelClick={() => {
                setTwoFactorAuth(false);
              }}
              setTwoFactorCompleted={() => {
                setOpenDocument(true);
              }}
              moduleName={MODULE_NAME.RESIDENCE_DOCUMENT_ACCESS}
              currentRole={currentRole}
            />
          ) : null}
      </Grid >
    </Grid >
  );
}
