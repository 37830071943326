/* eslint-disable  */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardContent,
  Grid,
  RACCheckBox,
  RACRadio,
  Typography,
  RACButton,
  RACToggle
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import RentalResidenceInfo from "./RentalResidenceInfo";
import OwnResidenceInfo from "./OwnResidenceInfo";
import {
  DynamicVerification,
  EmployerDetails,
  IdentityDetailsInfo,
  ParamType,
  ReferenceDetail,
  ResidenceInfo,
  ResidenceInfoProps,
} from "../../../interface/globalInterface";
import { isNotNullVariable, updateCustomerAndVerification, updateResidenceInfo, verifiedResidenceDisable } from "../Utils";
import moment from "moment";
import CustomerBasicInfo from "../CustomerBasicInfo/CustomerBasicInfo";
import { VerificationComponent } from "../VerifiedComponent";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { useParams } from "react-router-dom";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";
import { DynamicVerifiedStatus } from "../DynamicVerifiedStatus";
import { MODULE_NAME } from "../../../constants/constants";
import { ReactComponent as ContactIcon } from '../../../../CustomerRefactor/assets/images/ContactIcon.svg';

export default function ResidenceDetails({
  value,
  setterData,
}: ResidenceInfoProps) {
  const classes = globalStyles();
  const history = useHistory()

  let { setScreenLoader, residenceEditClicked, setresidenceEditClicked, setGetCustomerInfoResponse,
    setGetApprovalRes,
    residenceInfoCopy, setResidenceInfoCopy, currentRole, currentEmpId, configurations, residenceDoc, residenceDocumentCopy, setResidenceDocumentCopy, setResidenceDoc, verificationDocuments, residenceVerificationToggle, setresidenceVerificationToggle,
    setIsResidenceDocDeleted, isResidenceDocDeleted, residenceDocId, setResidenceDocId
  } = useContext(UpdateCustomerContext);


  let { residenceInfo, setResidenceInfo } = setterData;
  useEffect(() => {
    setresidenceVerificationToggle(residenceInfo?.verified)
    residenceInfoCopy = residenceInfo
    setResidenceInfoCopy(residenceInfo)
    setResidenceDocumentCopy(residenceDoc)

    if (verificationDocuments) {
      console.log("VerificationDocuments", verificationDocuments);
      const resideceDocument: any = verificationDocuments?.filter(
        (doc: { refCode: any }) => doc.refCode === "RESDOC"
      );
      // Find the most recent one
      if (resideceDocument && resideceDocument?.length > 0) {
        const recentResidenceDoc: any = resideceDocument?.reduce(
          (latest: any, current: any) => {
            return new Date(latest.createdDate) > new Date(current.createdDate)
              ? latest
              : current;
          }
        );
        console.log("recentResidenceDocument", recentResidenceDoc?.file);
        if (recentResidenceDoc?.file) {
          setResidenceDoc(recentResidenceDoc?.file)
          setResidenceDocumentCopy(recentResidenceDoc?.file)
          setResidenceDocId(recentResidenceDoc?.documentId)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationDocuments])





  const { customerId } = useParams<ParamType>();

  const [secondLevelAuth, setsecondLevelAuth] = useState<boolean>(false)

  //  Fucntion for checking the all keys and values are same or not
  function areObjectsEqual(obj1: any, obj2: any) {
    // Get the keys of each object
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // First check if they have the same number of keys
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if all keys and values are the same
    for (let key of keys1) {
      // Make sure the value types are the same and the values themselves are the same
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }



  const handleSaveButton = async () => {
    setsecondLevelAuth(false)
    setScreenLoader(true);
    console.log('residenceInfoooo', residenceInfo, isResidenceDocDeleted);
    let udpateData: any = residenceInfo;
    udpateData.modifiedBy = currentEmpId;
    let uploadDocPayload: any = ''
    if (residenceDocumentCopy) {
      uploadDocPayload = {
        isVerificationDocument: true,
        customerApprovalId: residenceInfo?.decisionId,
        documents: [
          {
            documentType: 'RESDOC',
            file: residenceDocumentCopy
          }
        ]
      }
    } else if (!residenceDocumentCopy && isResidenceDocDeleted) {
      uploadDocPayload = {
        action: 5,
        documentId: residenceDocId,
        isVerificationDocument: true,
        customerApprovalId: residenceInfo?.decisionId,
      }
    }
    const updateDetailsResponse: boolean = await updateCustomerAndVerification(udpateData, "RESI", customerId ? customerId : '', setGetCustomerInfoResponse,
      setGetApprovalRes, '', '', residenceDocumentCopy == residenceDoc ? '' : uploadDocPayload, configurations?.EnableCustomerVerificationPhaseTwo, history);
    setresidenceEditClicked(false)
    setScreenLoader(false);
    console.log("updateDetailsResponseupdateDetailsResponse", updateDetailsResponse, residenceVerificationToggle)
    if (updateDetailsResponse) {

      setResidenceDoc(residenceDocumentCopy)
      setResidenceDocumentCopy(residenceDocumentCopy)
      residenceInfo = {
        ...residenceInfo, verified: residenceVerificationToggle, verifiedBy: currentEmpId, verifiedSource: 'RACPad',
        verifiedDate: moment().format('MM/DD/YYYY'), residenceInfoError: {
          ...residenceInfo.residenceInfoError,
          landlordsFirstNameError: false,
          landlordsLastNameError: false,
          landlordsPhoneNumberError: false,
          mortgageCompanyNameError: false,
          landlordOrAptFirstNameError: false,
          residenceTenureTypeError: false,
          landlordOrAptPhoneNumberError: false,
        }
      }
      setResidenceInfo(residenceInfo)
      setResidenceInfoCopy(residenceInfo)
    }
    else {
      setResidenceInfo(residenceInfoCopy)
    }
  }

  const validateInputFields = async () => {
    let result: boolean = true;
    if (residenceInfo?.rentalType === 'RENT') {
      setResidenceInfo((prevState: any) => ({
        ...prevState,
        residenceInfoError: {
          ...prevState.residenceInfoError,
          mortgageCompanyName: false
        }
      }));
      if (configurations?.EnableCustomerVerificationPhaseTwo) {
        // Validate landlords/Apt Name 
        if (!residenceInfo?.rentalDetails?.landlordOrAptFirstName && !residenceDocumentCopy) {
          setResidenceInfo((prevState: any) => ({
            ...prevState,
            residenceInfoError: {
              ...prevState.residenceInfoError,
              landlordOrAptFirstNameError: true,
            },
          }));
          result = false;
        }
        else {
          setResidenceInfo((prevState: any) => ({
            ...prevState,
            residenceInfoError: {
              ...prevState.residenceInfoError,
              landlordOrAptFirstNameError: false,
            },
          }));
        }

        // Validate Residence Tenure type
        if (!residenceInfo?.rentalDetails?.residenceTenureType && !residenceDocumentCopy) {
          setResidenceInfo((prevState: any) => ({
            ...prevState,
            residenceInfoError: {
              ...prevState.residenceInfoError,
              residenceTenureTypeError: true,
            },
          }));
          result = false;
        }
        else {
          setResidenceInfo((prevState: any) => ({
            ...prevState,
            residenceInfoError: {
              ...prevState.residenceInfoError,
              residenceTenureTypeError: false,
            },
          }));
        }
        // Validate landlordsPhoneNumber length
        if (residenceInfo?.rentalDetails?.landlordOrAptPhoneNumber?.length != 10 && residenceInfo?.rentalDetails?.landlordOrAptPhoneNumber?.length != 14 && !residenceDocumentCopy) {
          setResidenceInfo((prevState: any) => ({
            ...prevState,
            residenceInfoError: {
              ...prevState.residenceInfoError,
              landlordOrAptPhoneNumberError: true,
            },
          }));
          result = false;
        }
        else {
          setResidenceInfo((prevState: any) => ({
            ...prevState,
            residenceInfoError: {
              ...prevState.residenceInfoError,
              landlordOrAptPhoneNumberError: false,
            },
          }));
        }
      }
      else {
        if (residenceInfo?.rentalType === 'RENT') {
          // Validate landlordsFirstName
          if (!residenceInfo?.rentalDetails?.landlordsFirstName) {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                landlordsFirstNameError: true,
              },
            }));
            result = false;
          }
          else {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                landlordsFirstNameError: false,
              },
            }));
          }
          // Validate landlordsLastName
          if (!residenceInfo?.rentalDetails?.landlordsLastName) {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                landlordsLastNameError: true,
              },
            }));
            result = false;
          }
          else {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                landlordsLastNameError: false,
              },
            }));
          }
          // Validate landlordsPhoneNumber length
          if (residenceInfo?.rentalDetails?.landlordsPhoneNumber.length != 10 && residenceInfo?.rentalDetails?.landlordsPhoneNumber.length != 14) {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                landlordsPhoneNumberError: true,
              },
            }));
            result = false;
          }
          else {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                landlordsPhoneNumberError: false,
              },
            }));
          }
        }
        else if (residenceInfo?.rentalType === 'OWN') {
          // Validate mortgageCompanyName

          setResidenceInfo((prevState: any) => ({
            ...prevState,
            residenceInfoError: {
              ...prevState.residenceInfoError,
              landlordsFirstNameError: false,
              landlordsLastNameError: false,
              landlordsPhoneNumberError: false,
            },
          }));

          if (residenceInfo?.mortgage && !residenceInfo?.ownResidenceDetails?.mortgageCompanyName) {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                mortgageCompanyNameError: true,
              },
            }));
            result = false;
          }
          else {
            setResidenceInfo((prevState: any) => ({
              ...prevState,
              residenceInfoError: {
                ...prevState.residenceInfoError,
                mortgageCompanyNameError: false,
              },
            }));
          }
        }
      }

    }


    return result;
  };



  // Save Button Click

  const residenceSaveBtnClick = async () => {
    const isValid = await validateInputFields()
    console.log("isValid", isValid)
    if (isValid) {
      residenceVerificationToggle ? setsecondLevelAuth(true) : handleSaveButton()
    }
  }

  return (
    <Grid container>

      {secondLevelAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setsecondLevelAuth(false)}
          setTwoFactorCompleted={(e: any) => handleSaveButton()}
          moduleName={MODULE_NAME.RESIDENCE_VERIFICATION}
          currentRole={currentRole}
        />
      ) : null}
      <Card
        className={`${classes.width100p} ${classes.mt10px} ${classes.card}`}
      >
        <CardContent className={`${classes.width100p}`}>
          <Grid container>
            <CustomerBasicInfo />
            <Grid item md={12} >
              <Grid item className={classes.floatLeft} >
                <Typography
                  component="label"
                  className={`${classes.formLabel} ${classes.width100p} ${classes.floatLeft}`}
                  style={{
                    fontSize: '18px',
                    fontFamily: 'OpenSans-bold',
                    marginTop: '32px'
                  }}
                >
                  Residence Information
                </Typography>
                <Typography
                  component="label"
                  className={`${classes.formLabel} ${classes.width100p} ${classes.floatLeft}`}
                >
                  Does the customer rent or own the residence? {configurations?.EnableCustomerVerificationPhaseTwo ? <span ><span style={{ color: 'red' }}>*</span>       {/* <ContactIcon style={{ marginBottom: '-5px' }} /> */}</span> : null}
                </Typography>

                <Grid
                  item
                  className={`${classes.displayInlineBlock} ${classes.pl0}`}
                >
                  <RACRadio
                    disabled={!residenceEditClicked}
                    checked={value.rentalType == "RENT"}
                    onChange={() => {
                      updateResidenceInfo(residenceInfo, setResidenceInfo, {
                        rentalType: 'RENT',
                        mortgage: false
                      })
                    }}
                    value={""}
                    label="Rent"
                  />
                </Grid>
                <Grid
                  item
                  className={`${classes.displayInlineBlock} ${classes.pl0}`}
                >
                  <RACRadio
                    disabled={!residenceEditClicked}
                    checked={value.rentalType == "OWN"}
                    onChange={() => {
                      updateResidenceInfo(residenceInfo, setResidenceInfo, { rentalType: 'OWN' })
                    }}
                    label="Own"
                    value={""}
                  />
                </Grid>
              </Grid>
            </Grid>
            {value.rentalType == "RENT" ? (
              <RentalResidenceInfo value={value} setterData={{ residenceInfo, setResidenceInfo }} />
            ) : value.rentalType == "OWN" ? (
              <OwnResidenceInfo value={value} setterData={{ residenceInfo, setResidenceInfo }} />
            ) : null}

            {(!residenceInfo.verified || residenceEditClicked) && residenceInfo?.decisionId ? <Grid container className={classes.width100p}>
              <Grid container md={12} className={`${classes.mt20px}`}>
                <Grid item md={12}>
                  <Typography
                    className={`${classes.fontSize16px} ${classes.fontOpensansBold}`}
                  >
                    Verification
                  </Typography>
                  <Typography
                    className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.lightGreyColor} ${classes.mt5px}`}
                  >
                    Please enter all required fields to enable Verification
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12} className={`${classes.mt20px}`}>

                <Grid item md={3} lg={3} xs={6}>
                  <RACToggle
                    rightText={true}
                    rightLabel={`Verified`}
                    labelClassName={`${classes.ml10px}`}
                    toggleColor="#0d6efd"
                    GridClassName={`${classes.displayFlex} ${classes.mt10px}`}
                    checked={residenceVerificationToggle}
                    OnChange={(e: any) => {
                      setresidenceVerificationToggle(!residenceVerificationToggle);
                      setResidenceInfo({ ...residenceInfo, residenceVerificationToggle: !residenceVerificationToggle })
                    }}

                    disabled={configurations?.EnableCustomerVerificationPhaseTwo ? (residenceInfo?.rentalType == 'RENT' ? !((residenceEditClicked && residenceInfo?.rentalDetails?.landlordOrAptFirstName && residenceInfo?.rentalDetails?.residenceTenureType && (residenceInfo?.rentalDetails?.landlordOrAptPhoneNumber?.length == 10 || residenceInfo?.rentalDetails?.landlordOrAptPhoneNumber?.length == 14)) || residenceEditClicked && residenceDocumentCopy) : !(residenceEditClicked && residenceDocumentCopy)) : (!((residenceInfo?.rentalType == 'RENT' && residenceEditClicked && residenceInfo?.rentalDetails?.landlordsFirstName && residenceInfo?.rentalDetails?.landlordsLastName && residenceInfo?.rentalDetails?.landlordsPhoneNumber && (residenceInfo?.rentalDetails?.landlordsPhoneNumber?.length == 10 || residenceInfo?.rentalDetails?.landlordsPhoneNumber?.length == 14)) || (residenceEditClicked && residenceInfo?.rentalType == 'OWN' && (residenceInfo?.ownResidenceDetails?.moveInDate || (residenceInfo?.mortgage && residenceInfo?.ownResidenceDetails?.mortgageCompanyName)))))}
                  />
                </Grid>
              </Grid>
            </Grid> : null}

          </Grid>


          <Grid
            container
            className={`${classes.mt10px} ${classes.displayFlex} ${classes.flexEnd} ${classes.pr2p}`}
            md={12}

          >
            <Grid item md={10}>
              {residenceInfo.verified ? (
                <DynamicVerifiedStatus
                  value={{
                    verifiedBy: residenceInfo.verifiedBy || '',
                    verifiedSource: residenceInfo.verifiedSource || '',
                    verifiedOn: residenceInfo.verifiedDate,
                  }}
                />
              ) : null}


            </Grid>
            <Grid item md={2} style={{ textAlign: 'right' }}>
              {residenceEditClicked ? (
                <RACButton
                  variant="outlined"
                  color="primary"
                  className={`${classes.alignRight}`}
                  onClick={() => {
                    setresidenceVerificationToggle(residenceInfoCopy?.verified),
                      setresidenceEditClicked(false),
                      setResidenceInfo(residenceInfoCopy),
                      setResidenceDocumentCopy(residenceDoc);
                    setIsResidenceDocDeleted(false);
                  }
                  }
                >
                  Cancel
                </RACButton>

              ) : null}
              {residenceEditClicked ?
                <RACButton
                  variant="contained"
                  color="primary"
                  className={`${classes.ml10px} ${classes.alignRight}`}
                  disabled={configurations?.EnableCustomerVerificationPhaseTwo ? residenceInfo?.rentalType === 'OWN' ? !(residenceDocumentCopy && !(residenceDoc == residenceDocumentCopy)) && (!residenceInfoCopy?.rentalType ? !residenceDocumentCopy : true) && !residenceInfoCopy?.rentalType ? true : residenceInfoCopy?.rentalType == 'RENT' ? !residenceDocumentCopy : (JSON.stringify(residenceInfo) === JSON.stringify(residenceInfoCopy)) : !(!(JSON.stringify(residenceInfo) === JSON.stringify(residenceInfoCopy)) || !(residenceDoc == residenceDocumentCopy)) : false}
                  onClick={async () => {
                    residenceSaveBtnClick()
                  }}
                >
                  Save
                </RACButton>
                :
                <RACButton
                  variant="contained"
                  color="primary"
                  className={`${classes.ml10px} ${classes.alignRight}`}
                  onClick={() => { setresidenceEditClicked(true); /* setResidenceInfo({ ...residenceInfo, residenceVerificationToggle: false }) */ }}
                >
                  Edit
                </RACButton>}
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </Grid >
  );
}

