/* eslint-disable */
import React, { createContext, useState } from "react";
import { Grid } from "@rentacenter/racstrap";

import { DropdownOptions, ResidenceInfo, tabList } from "../interface/globalInterface";

import {
  AddressDetails,
  AddressDetailsBinder,
  AddressInformationProps,
  CustomerApproval,
  EmployerDetails,
  GetAgreement,
  IdentityDetailsInfo,
  VehicleInfomation,
} from "../interface/globalInterface";

import {
  AgreementDetails,
  CommunicationInformation,
  Configurations,
  ContactInformations,
  CustomerBasicInformation,
  FaxSheetInformation,
  LegalHoldInfomation,
  ReferenceInformation,
  SkipHardStatus,
  TaxExemptInformation,
} from "../interface/updateCustomerInterface";

import {
  AGREEMENT_DETAILS,
  BASIC_INFORMATION_TEMPLATE,
  COMMUNICATION_PREFERENCE_TEMPLATE,
  CONFIGURATION_INFO_TEMPLATE,
  CONTACT_INFORMATION_TEMPLATE,
  DEFAULT_DROPDOWN_OPTION,
  EMPLOYER_INFO_TEMPLATE,
  EMPTY_STRING,
  FAX_SHEET_INFO_TEMPLATE,
  IDENTITY_DETAILS,
  LEGAL_HOLD_INFO_TEMPLATE,
  REFERENCE_INFO_ERROR_MESSAGE,
  REFERENCE_INFO_TEMPLATE,
  RESIDENCE_INFO_TEMPLATE,
  SKIP_HARD_TEMPLATE,
  TAX_EXEMPT_TEMPLATE,
  VEHICLE_INFORMATION_TEMPLATE,
} from "../constants/constants";
import { globalStyles } from "../jsStyles/globalStyles";

export const UpdateCustomerContext = createContext<any>(EMPTY_STRING);

export function UpdateCustomerContextProvider(props: any) {
  const classes = globalStyles();
  //Loaders handling state variables
  const [screenLoader, setScreenLoader] = useState<boolean>(false);
  const [loadFaxSheet, setLoadFaxSheet] = useState<boolean>(false);
  const [govIdFocused, setGovIdFocused] = useState<boolean>(false);
  const [customerAddresses, setCustomerAddress] = useState<any[]>([]);
  //Global Variables
  const [coworkerRole, setCoworkerRole] = useState<string>(EMPTY_STRING);
  let [getApprovalRes, setGetApprovalRes] = useState<any>([]);
  const [updateOccured, setUpdateOccured] = useState<boolean>(false);
  const [Agrtransferinit, setAgrtransferinit] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [configurations, setConfigurations] = useState<Configurations>({
    ...CONFIGURATION_INFO_TEMPLATE,
  });

  /**** Basic Information ****/
  const [customerBasicInformation, setCustomerBasicInformation] =
    useState<CustomerBasicInformation>({ ...BASIC_INFORMATION_TEMPLATE });
  const [disableWebLink, setDisableWebLink] = useState<boolean>(false);
  const [showCustomerInfo, setShowCustomerInfo] = useState<boolean>(false);

  //Variable used in Footer
  const [agreementInformation, setAgreementInformation] =
    useState<AgreementDetails>({ ...AGREEMENT_DETAILS });

  const [showSplit, setShowSplit] = useState<boolean>(false);

  const [showTransferAgreemnt, setShowTransferAgreemnt] = useState<boolean>(false);

  const [showDecryptedValues, setShowDecryptedValues] = useState<boolean>(false);

  const [communicationInformation, setCommunicationInformation] = useState<CommunicationInformation>({
    ...COMMUNICATION_PREFERENCE_TEMPLATE,
  });

  const [coCustomerInformation, setCoCustomerInformation] = useState<any>();

  //State Variables used in Legal Component
  const [legalInformation, setLegalInformation] = useState<LegalHoldInfomation>(
    { ...LEGAL_HOLD_INFO_TEMPLATE }
  );
  const [skipStolenHardStatus, setSkipStolenHardStatus] = useState<SkipHardStatus>({
    ...SKIP_HARD_TEMPLATE,
  });

  const [contactInformation, setContactInformation] = useState<
    ContactInformations[]
  >([{ ...CONTACT_INFORMATION_TEMPLATE }]);
  //State Variables used to store dropdowns options
  const [govtIDTypeOptions, setGovtIDTypeOptions] = useState<DropdownOptions[]>(
    [...DEFAULT_DROPDOWN_OPTION]
  );
  const [stateOptions, setStateOptions] = useState<DropdownOptions[]>([
    ...DEFAULT_DROPDOWN_OPTION,
  ]);
  const [saluationOptions, setSaluationOptions] = useState<DropdownOptions[]>([
    ...DEFAULT_DROPDOWN_OPTION,
  ]);
  const [suffixOptions, setSuffixOptions] = useState<DropdownOptions[]>([
    ...DEFAULT_DROPDOWN_OPTION,
  ]);
  const [methodOfContactOptions, setMethodOfContactOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [phoneTypeOptions, setPhoneTypeOptions] = useState<DropdownOptions[]>([
    ...DEFAULT_DROPDOWN_OPTION,
  ]);
  const [bestTimeToCallOptions, setBestTimeToCallOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [routeOptions, setRouteOptions] = useState<DropdownOptions[]>([
    ...DEFAULT_DROPDOWN_OPTION,
  ]);
  const [taxStatusOptions, setTaxStatusOptions] = useState<DropdownOptions[]>([
    ...DEFAULT_DROPDOWN_OPTION,
  ]);
  const [legalHoldOptions, setLegalHoldOptions] = useState<DropdownOptions[]>([
    ...DEFAULT_DROPDOWN_OPTION,
  ]);
  const [payDayFrequencyOptions, setPayDayFrequencyOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [relationshipOptions, setRelationshipOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [weeklyDropdownOptions, setWeeklyDropdownOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [monthlyDropdownOptions, setMonthlyDropdownOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [semiMonthlyDropdownOptions, setSemiMonthlyDropdownOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);

  const [vehicleInformation, setVehicleInformation] = useState<
    VehicleInfomation[]
  >([{ ...VEHICLE_INFORMATION_TEMPLATE }]);
  const [customerInfoChange, setcustomerInfoChange] = useState<boolean>(false);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [dEHistoryResponse, setDEHistoryResponse] = useState<
    CustomerApproval[]
  >([]);
  const [hasMore, sethasMore] = useState<boolean>(true);
  const [dEHistoryPageDisplay, setDEHistoryPageDisplay] = useState<number>(0);
  const [deHistoryQueryParams, setdeHistoryQueryParams] = useState<any>({
    offset: 0,
    limit: 0,
  });
  const [isExchangeEnabled, setExchangeEnabled] = useState<any>(false);
  const [exchangePeriod, setExchangePeriod] = useState("");
  const [returnPeriod, setReturnPeriod] = useState("");
  const [racExchangeFuncFlag, setRacExchangeFuncFlag] =
    useState<boolean>(false);
  const [agreementInfo, setagreementInfo] = useState<[] | any>([]);
  const [splitCoCustomerAvailble, setSplitCoCustomerAvailble] = useState(false);
  const [splitEligibleAgrs, setsplitEligibleAgrs] = useState([]);
  const [splitBlockOpen, setSplitBlockOpen] = useState(false);
  const [getAgrbyIdresponse, setGetAgrbyIdresponse]: any = useState([]);
  const [storeReinstatementAllowed, setstoreReinstatementAllowed] =
    useState<any>(false);
  const [activeExpand, setactiveExpand] = useState<boolean>(true);
  const [inActiveExpand, setinActiveExpand] = useState<boolean>(true);
  const [pending, setpending] = useState<boolean>(true);
  const [reInstate, setreInstate] = useState<boolean>(true);
  const [agrCheckBoxId, setagrCheckBoxId] = useState<any>({
    id: "",
    checkBox: true,
  });
  const [checkAction, setcheckAction] = useState<number[] | any>("");
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState<any>(null);
  const [exchangeSelected, setExchangeSelected] = useState<any>("");
  const [rentPaid, setRentPaid] = useState<any>(0);
  const [exchangeAgreementId, setAgreementId] = useState<any>(0);
  const [exchangeAgreementNumber, setExchangeAgreementNumber] =
    useState<any>("");
  const [suspenseAmount, setSuspenseAmount] = useState<any>("");
  const [agrHistoryPageDisplay, setAgrHistoryPageDisplay] = useState<number>(0);
  const [defaultAddress, setDefaultAddress] = useState("SUG");
  const [matchCode, setmatchCode] = useState<any>("");
  const [addressErrorMsg, setAddressErrorMsg] = useState<any>("");
  const [suggestedAddress, setSuggestedAddress] = useState<any>();
  const [ValidateIsOpen, setValidateIsOpen] = useState(false);
  const [validatedAddresses, setValidatedAddress] = useState<any>({});
  const [canSuggestAddress, setCanSuggestAddress] = useState(false);
  const [editInputBox, seteditInputBox] = useState(true);
  const [AddressDoc, setAddressDoc] = useState<boolean>(false);
  const [runAddressDoc, setrunAddressDoc] = useState<"P" | "M" | "">("");
  const [BtnIsdisabled1, setBtnIsdisabled1] = useState(true);
  const [BtnIsdisabled2, setBtnIsdisabled2] = useState(false);
  const [editInputBoxM, seteditInputBoxM] = useState(true);
  const [cantSuggestAddress, setCantSuggestAddress] = useState(false);
  const [addressIndex, setAddressIndex] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxExemptInformation, setTaxexemptInformation] =
    useState<TaxExemptInformation>({ ...TAX_EXEMPT_TEMPLATE });
  const [faxSheetDetails, setFaxSheetDetails] = useState<FaxSheetInformation>({
    ...FAX_SHEET_INFO_TEMPLATE,
  });
  const [federalTaxId, setFederalTaxId] = useState<string>(EMPTY_STRING);
  const [residenceInfo, setResidenceInfo] = useState<ResidenceInfo>({ ...RESIDENCE_INFO_TEMPLATE });
  const [referenceInformation, setReferenceInformation] = useState<
    ReferenceInformation[]
  >([{ ...REFERENCE_INFO_TEMPLATE }]);
  const [inactivePhone, setInactivePhone] = useState<ContactInformations[]>([]);
  const [alertsOptions, setAlertsOptions] = useState<any[]>([]);
  const [assignedAlerts, setAssignedAlerts] = useState<any[]>([]);
  const [updatedAlerts, setUpdatedAlerts] = useState<any[]>([]);
  const [address, setAddress] = useState<AddressInformationProps>({
    activeAddresses: [],
    inActiveAddresses: [],
  });
  const [employerDetails, setEmployerDetails] = useState<EmployerDetails[]>([
    { ...EMPLOYER_INFO_TEMPLATE },
  ]);
  const [editedAddress, setEditedAddress] = useState<AddressDetailsBinder[]>(
    []
  );
  const [activeAddress, setActiveAddress] = useState<AddressDetailsBinder[]>(
    []
  );
  const [inActiveAddress, setInActiveAddress] = useState<any[]>([]);
  let [getCustomerInfoResponse, setGetCustomerInfoResponse] = useState<any>();
  const [updatedCustomerBasicInfo, setUpdatedCustomerBasicInfo] =
    useState<CustomerBasicInformation>({ ...BASIC_INFORMATION_TEMPLATE });
  const [updatedCommunicationInformation, setUpdatedCommunicationInformation] =
    useState<CommunicationInformation>({
      ...COMMUNICATION_PREFERENCE_TEMPLATE,
    });
  const [identityDetails, setIdentityDetails] =
    useState<IdentityDetailsInfo>(IDENTITY_DETAILS);
  const [actualIdentityDetails, setActualIdentityDetials] =
    useState<IdentityDetailsInfo>(IDENTITY_DETAILS);
  const [identityErrorMessage, setIdentityErrorMessage] = useState<any>({
    governmentId: '',
    governmentIdType: '',
    issuedState: '',
    verified: false,
    verifiedDate: '',
    overrideEnabled: false,
    decisionEngineId: '',
    verifiedSource: ''
  });
  const [primaryAddress, setPrimaryAddress] = useState<AddressDetails[]>([]);
  const [primaryAddPopUp, setPrimaryAddPopUp] = useState<boolean>(false);
  const [primaryAddressIndex, setPrimaryAddressIndex] = useState<number>();
  const [multipleAgreementPrimaryAddress, setMultipleAgreementPrimaryAddress] = useState<boolean>(false);
  const [radioIndex, setRadioIndex] = useState<number | null>(null);
  const [primaryAddressUpdated, setPrimaryAddressUpdated] = useState<boolean>(false);
  const [updatePopUp, setShowUpdatePopUp] = useState<boolean>(false);
  const [createAgrClicked, setcreateAgrClicked] = useState<any>(false);
  const [PopupClass, setPopupClass] = useState<string>(`${classes.customMenuContainer} ${classes.popUpHide}`);
  const [comingSoonPopup, setcomingSoonPopup] = useState<boolean>(false);
  const [isCustomerInfoUpdate, setIsCustomerInfoUpdate] =
    useState<boolean>(false);
  const [validateResult, setValidateResult] = useState<any | []>([]);
  const [isMaskedDataHasChanged, setisMaskedDataHasChanged]: any = useState({ ssn: false, govtId: false, dob: false });
  const [taxToggle, setTaxToggle] = useState<boolean>(false);
  const [residenceEditClicked, setresidenceEditClicked] = useState<boolean>(false)
  const [residenceInfoCopy, setResidenceInfoCopy] = useState<any>()
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [popupMessage, setPopupMessage] = useState<string>(EMPTY_STRING);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupType, setPopupType] = useState<string>(EMPTY_STRING);
  const [updatedContactInfo, setUpdatedContactInfo] = useState<ContactInformations[]>([{ ...CONTACT_INFORMATION_TEMPLATE }]);
  const [contactInfoErrorMessage, setContactInfoErrorMessage] = useState<ContactInformations[]>([{ ...CONTACT_INFORMATION_TEMPLATE }]);
  const [updatedEmployerDetails, setUpdatedEmployerDetails] = useState<EmployerDetails[]>([{ ...EMPLOYER_INFO_TEMPLATE }]);
  const [employerDetailsErrorMessage, setEmployerDetailsErrorMessage] = useState<any>([{
    basicInfo: {
      companyName: '',
      employmentYears: '',
      phoneNumber: '',
      doNotCallEnabled: false,
      isEdited: false,
    },
    incomeVerification: {
      paydayFrequency: '',
      dayPaid: '',
      takeHomePay: '',
      isEdited: false,
    },
    addressInfo: {
      addressLine1: '',
      addressLine2: '',
      zip: '',
      city: '',
      state: '',
      doNotVisit: false,
      isEdited: false,
    },
    verified: false,
    active: false,
    verifiedDate: '',
    employerDetailsId: null,
    overrideEnabled: false,
    isEditEnabled: false,
    verifiedBy: '',
    decisionEngineId: '',
    verifiedSource: '',
    verifyCode: '',
  }]);
  const [createAgreementClick, setCreateAgreementClick] = useState<boolean>(false);
  const [activetab, setactivetab] = useState<tabList | undefined>(
    tabList.CUSTOMERINFO
  );
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isAuth, setisAuth] = useState(false);
  const [restatementPopUp, setrestatementPopUp] = useState<boolean>(false);
  const [createCoCustomerPopUp, setcreateCoCustomerPopUp] = useState<boolean>(false);
  const [isSuccess, setisSuccess] = useState<boolean>(false);
  const [cantAccess, setCantAccess] = useState<boolean>(false);
  const [currentRole, setCurrentRole] = useState<string>('');
  const [coworkerIds, setCoworkerIds] = useState<string[]>(['']);
  const [currentEmpId, setCurrentEmpId] = useState<string>(EMPTY_STRING);
  const [clubInfo, setClubInfo] = useState<any>();
  const [activateClubObj, setactivateClubObj] = useState<boolean>(false);
  const [activateClub, setactivateClub] = useState<boolean>(false);
  const [showcocustomerpopup, setshowcocustomerpopup] = useState(false);
  const [restatementCustomer, setrestatementCustomer] = useState(false);
  const [legalHoldedScreen, setLegalHoldedScreen] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [AlertLoad, setAlertLoad] = useState<any | []>([]);
  const [alertUpdateObj, setAlertUpdateObj] = useState<any | []>([]);
  const [activeAlert, setActiveAlert] = useState<[] | any>([]);
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [isPrimaryNotSet, setIsPrimaryNotSet] = useState<string>('');
  const [referenceErrorTitle, setReferenceErrorTitle] = useState<string>(REFERENCE_INFO_ERROR_MESSAGE);
  const [updatedReferenceDetails, setUpdatedReferenceDetails] = useState<ReferenceInformation[]>([{ ...REFERENCE_INFO_TEMPLATE }]);
  const [referenceErrorMessage, setReferenceErrorMessage] = useState<ReferenceInformation[]>([{ ...REFERENCE_INFO_TEMPLATE }]);
  const [exchangeNavigationPopup, setExchangeNavigationPopup] = useState<boolean>(false);
  const [verificationNotRequiredReason, setVerificationNotRequiredReason] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [residenceTenureTypeOptions, setResidenceTenureTypeOptions] = useState<
    DropdownOptions[]
  >([...DEFAULT_DROPDOWN_OPTION]);
  const [btnClicked, setBtnClicked] = useState<any>(null);
  const [verificationNotrequiredEnable, setVerificationNotrequiredEnable] =
    useState<any>(false);
  let [verificationNotrequiredPayload, setVerificationNotrequiredPayload] =
    useState<any>({
      verifyType: "",
      activetabType: "",
      verificationDocument: "",
    });

  const [notRequiredprovided, setnotRequiredprovided] = useState<any>([0]);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [referenceEditEnabled, setReferenceEditEnabled] = useState<boolean>(true);
  const [timeLeft, setTimeLeft] = useState<any>(0);
  const [isTimerActive, setIsTimerActive] = useState('')
  const [intellicheckStatus, setIntellicheckStatus] = useState('');
  const [intellicheckmismatchedValues, setIntellicheckmismatchedValues] = useState('');
  const [sendIdentityVerificationTextOpen, setSendIdentityVerificationTextOpen] = useState(false)
  const [incomeDoc, setIncomeDoc] = useState<string>('');
  const [residenceDoc, setResidenceDoc] = useState<string>('');
  const [verificationDocuments, setVerificationDocuments] = useState<any>('');
  const [openSendDigitalOrderFormLink, setOpenSendDigitalOrderFormLink] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successfullPopup, setSuccessfullPopup] = useState<boolean>(false);
  const [residenceDocumentCopy, setResidenceDocumentCopy] = useState<any>('');
  const [incomeDocCopy, setincomeDocCopy] = useState<any>('')

  const [incomeSourceDropDownOptions, setIncomeSourceDropDownOptions] = useState<DropdownOptions[]>([...DEFAULT_DROPDOWN_OPTION])
  const [isIncomeDocDeleted, setIsIncomeDocDeleted] = useState<boolean>(false);
  const [incomeDocId, setIncomeDocId] = useState<string>('');
  const [residenceVerificationToggle, setresidenceVerificationToggle] = useState<any>(false);
  const [isResidenceDocDeleted, setIsResidenceDocDeleted] = useState<boolean>(false);
  const [residenceDocId, setResidenceDocId] = useState<string>(``);
  const [mobileNumber, setMobileNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
  const [intellicheckDetails, setIntellicheckDetails] = useState<any>('');

  return (
    <Grid>
      <UpdateCustomerContext.Provider
        value={{
          residenceVerificationToggle, setresidenceVerificationToggle,
          residenceTenureTypeOptions, setResidenceTenureTypeOptions,
          verificationNotrequiredEnable, setVerificationNotrequiredEnable,
          verificationNotrequiredPayload, setVerificationNotrequiredPayload,
          notRequiredprovided, setnotRequiredprovided,
          screenLoader,
          setScreenLoader,
          updateOccured,
          setUpdateOccured,
          faxSheetDetails,
          setFaxSheetDetails,
          referenceInformation,
          setReferenceInformation,
          taxExemptInformation,
          setTaxexemptInformation,
          inactivePhone,
          setInactivePhone,
          updatedAlerts,
          setUpdatedAlerts,
          assignedAlerts,
          setAssignedAlerts,
          alertsOptions,
          setAlertsOptions,
          federalTaxId,
          setFederalTaxId,
          coworkerRole,
          setCoworkerRole,
          showSplit,
          setShowSplit,
          agreementInformation,
          setAgreementInformation,
          showTransferAgreemnt,
          setShowTransferAgreemnt,
          legalInformation,
          setLegalInformation,
          communicationInformation,
          setCommunicationInformation,
          contactInformation,
          setContactInformation,
          customerBasicInformation,
          setCustomerBasicInformation,
          coCustomerInformation,
          setCoCustomerInformation,
          skipStolenHardStatus,
          setSkipStolenHardStatus,
          govtIDTypeOptions,
          setGovtIDTypeOptions,
          stateOptions,
          setStateOptions,
          saluationOptions,
          setSaluationOptions,
          suffixOptions,
          setSuffixOptions,
          methodOfContactOptions,
          setMethodOfContactOptions,
          phoneTypeOptions,
          setPhoneTypeOptions,
          bestTimeToCallOptions,
          setBestTimeToCallOptions,
          routeOptions,
          setRouteOptions,
          legalHoldOptions,
          setLegalHoldOptions,
          taxStatusOptions,
          setTaxStatusOptions,
          relationshipOptions,
          setRelationshipOptions,
          weeklyDropdownOptions,
          setWeeklyDropdownOptions,
          monthlyDropdownOptions,
          setMonthlyDropdownOptions,
          semiMonthlyDropdownOptions,
          setSemiMonthlyDropdownOptions,
          payDayFrequencyOptions,
          setPayDayFrequencyOptions,
          Agrtransferinit,
          setAgrtransferinit,
          vehicleInformation,
          setVehicleInformation,
          customerInfoChange,
          setcustomerInfoChange,
          dropDownLoaded,
          setDropDownLoaded,
          dEHistoryResponse,
          setDEHistoryResponse,
          hasMore,
          sethasMore,
          isExchangeEnabled,
          setExchangeEnabled,
          exchangePeriod,
          setExchangePeriod,
          returnPeriod,
          setReturnPeriod,
          racExchangeFuncFlag,
          setRacExchangeFuncFlag,
          agreementInfo,
          setagreementInfo,
          splitCoCustomerAvailble,
          setSplitCoCustomerAvailble,
          splitEligibleAgrs,
          setsplitEligibleAgrs,
          splitBlockOpen,
          setSplitBlockOpen,
          getAgrbyIdresponse,
          setGetAgrbyIdresponse,
          storeReinstatementAllowed,
          setstoreReinstatementAllowed,
          activeExpand,
          setactiveExpand,
          inActiveExpand,
          setinActiveExpand,
          pending,
          setpending,
          reInstate,
          setreInstate,
          agrCheckBoxId,
          setagrCheckBoxId,
          checkAction,
          setcheckAction,
          secondLevelAuthenOn,
          setSecondLevelAuthenOn,
          exchangeSelected,
          setExchangeSelected,
          rentPaid,
          setRentPaid,
          exchangeAgreementId,
          setAgreementId,
          exchangeAgreementNumber,
          setExchangeAgreementNumber,
          suspenseAmount,
          setSuspenseAmount,
          agrHistoryPageDisplay,
          setAgrHistoryPageDisplay,
          deHistoryQueryParams,
          setdeHistoryQueryParams,
          dEHistoryPageDisplay,
          setDEHistoryPageDisplay,
          defaultAddress,
          setDefaultAddress,
          matchCode,
          setmatchCode,
          addressErrorMsg,
          setAddressErrorMsg,
          suggestedAddress,
          setSuggestedAddress,
          ValidateIsOpen,
          setValidateIsOpen,
          validatedAddresses,
          setValidatedAddress,
          canSuggestAddress,
          setCanSuggestAddress,
          editInputBox,
          seteditInputBox,
          AddressDoc,
          setAddressDoc,
          runAddressDoc,
          setrunAddressDoc,
          BtnIsdisabled1,
          setBtnIsdisabled1,
          BtnIsdisabled2,
          setBtnIsdisabled2,
          editInputBoxM,
          seteditInputBoxM,
          cantSuggestAddress,
          setCantSuggestAddress,
          addressIndex,
          setAddressIndex,
          isLoading,
          setIsLoading,
          address,
          setAddress,
          employerDetails,
          setEmployerDetails,
          editedAddress,
          setEditedAddress,
          activeAddress,
          setActiveAddress,
          inActiveAddress,
          setInActiveAddress,
          showCustomerInfo,
          setShowCustomerInfo,
          configurations,
          setConfigurations,
          showDecryptedValues,
          setShowDecryptedValues,
          disableWebLink,
          setDisableWebLink,
          getCustomerInfoResponse,
          setGetCustomerInfoResponse,
          isRequired,
          setIsRequired,
          updatedCustomerBasicInfo,
          setUpdatedCustomerBasicInfo,
          updatedCommunicationInformation,
          setUpdatedCommunicationInformation,
          identityDetails,
          setIdentityDetails,
          actualIdentityDetails,
          setActualIdentityDetials,
          identityErrorMessage,
          setIdentityErrorMessage,
          getApprovalRes,
          setGetApprovalRes,
          primaryAddress,
          setPrimaryAddress,
          primaryAddPopUp,
          setPrimaryAddPopUp,
          primaryAddressIndex,
          setPrimaryAddressIndex,
          multipleAgreementPrimaryAddress,
          setMultipleAgreementPrimaryAddress,
          radioIndex,
          setRadioIndex,
          primaryAddressUpdated,
          setPrimaryAddressUpdated,
          updatePopUp,
          setShowUpdatePopUp,
          loadFaxSheet,
          setLoadFaxSheet,
          taxToggle,
          setTaxToggle,
          createAgrClicked,
          setcreateAgrClicked,
          PopupClass,
          setPopupClass,
          comingSoonPopup,
          setcomingSoonPopup,
          isCustomerInfoUpdate,
          setIsCustomerInfoUpdate,
          validateResult,
          setValidateResult,
          isMaskedDataHasChanged,
          setisMaskedDataHasChanged,
          residenceInfo,
          setResidenceInfo,
          residenceEditClicked,
          setresidenceEditClicked,
          emailErrorMessage,
          setEmailErrorMessage,
          showPopup,
          setShowPopup,
          popupType,
          setPopupType,
          popupMessage,
          setPopupMessage,
          updatedContactInfo,
          setUpdatedContactInfo,
          contactInfoErrorMessage,
          setContactInfoErrorMessage,
          updatedEmployerDetails,
          setUpdatedEmployerDetails,
          setEmployerDetailsErrorMessage,
          employerDetailsErrorMessage,
          coworkerIds,
          setCoworkerIds,
          createAgreementClick,
          setCreateAgreementClick,
          activetab,
          setactivetab,
          errorPopup,
          setErrorPopup,
          isAuth,
          setisAuth,
          restatementPopUp,
          setrestatementPopUp,
          createCoCustomerPopUp,
          setcreateCoCustomerPopUp,
          isSuccess,
          setisSuccess,
          cantAccess,
          setCantAccess,
          currentRole,
          setCurrentRole,
          residenceInfoCopy,
          setResidenceInfoCopy,
          govIdFocused,
          setGovIdFocused,
          customerAddresses,
          setCustomerAddress,
          clubInfo,
          setClubInfo,
          activateClubObj,
          setactivateClubObj,
          currentEmpId,
          setCurrentEmpId,
          activateClub,
          setactivateClub,
          showcocustomerpopup,
          setshowcocustomerpopup,
          restatementCustomer,
          setrestatementCustomer,
          legalHoldedScreen,
          setLegalHoldedScreen,
          alertIsOpen,
          setAlertIsOpen,
          isAlertLoading,
          setIsAlertLoading,
          activeAlert,
          setActiveAlert,
          alertUpdateObj,
          setAlertUpdateObj,
          AlertLoad,
          setAlertLoad,
          isPrimaryNotSet,
          setIsPrimaryNotSet,
          referenceErrorTitle,
          setReferenceErrorTitle,
          updatedReferenceDetails,
          setUpdatedReferenceDetails,
          referenceErrorMessage,
          setReferenceErrorMessage,
          exchangeNavigationPopup,
          setExchangeNavigationPopup,
          verificationNotRequiredReason,
          setVerificationNotRequiredReason,
          btnClicked,
          setBtnClicked,
          editEnabled,
          setEditEnabled,
          referenceEditEnabled,
          setReferenceEditEnabled,
          timeLeft,
          setTimeLeft,
          isTimerActive, setIsTimerActive,
          intellicheckStatus, setIntellicheckStatus,
          intellicheckmismatchedValues, setIntellicheckmismatchedValues,
          sendIdentityVerificationTextOpen, setSendIdentityVerificationTextOpen,
          incomeDoc, setIncomeDoc,
          verificationDocuments, setVerificationDocuments,
          residenceDoc, setResidenceDoc,
          openSendDigitalOrderFormLink, setOpenSendDigitalOrderFormLink,
          errorMessage, setErrorMessage,
          successfullPopup, setSuccessfullPopup,
          residenceDocumentCopy, setResidenceDocumentCopy,
          incomeDocCopy, setincomeDocCopy,
          incomeSourceDropDownOptions, setIncomeSourceDropDownOptions,
          isIncomeDocDeleted, setIsIncomeDocDeleted,
          incomeDocId, setIncomeDocId,
          isResidenceDocDeleted, setIsResidenceDocDeleted,
          residenceDocId, setResidenceDocId,
          mobileNumber, setMobileNumber, 
          email, setEmail,
          intellicheckDetails, setIntellicheckDetails
        }}
      >
        {props.children}
      </UpdateCustomerContext.Provider>
    </Grid>
  );
}
